import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PersonalCodeService from "../../../services/PersonalCodeService";
import { Modal, Form, Input } from 'antd';
import { getEvidenceData } from "../../../util/getEvidenceData";
import { ArrowIcon } from "../../SvgComponents/ArrowIcon/ArrowIcon";

export const PersonalCodeModal = ({ personalCodeModal, setPersonalCodeModal, emailId, pendingSignature }) => {
    const { t } = useTranslation();
    const [titlePersonalCodeModal, setTitlePersonalCodeModal] = useState(t('personal_code.title'));
    const [filter, setFilter] = useState({
        PersonalCode: ''
    });
    const { PersonalCode } = filter;
    const [errorPersonalCode, setErrorPersonalCode] = useState(false);
    const [loadingPersonalCode, setLoadingPersonalCode] = useState(false);
    const [showMessageMaximumPersonalCode, setShowMessageMaximumPersonalCode] = useState(false);

    // On Init
    useEffect(() => {
        setTitlePersonalCodeModal(t('personal_code.title'));
    }, [t]);

    const handlePersonalCodeChange = (e) => {
        const { name, value } = e.target;

        setFilter({
            ...filter,
            [name]: value
        });
    };

    const handlePersonalCode = async () => {
        setLoadingPersonalCode(true);
        const evidenceData = await getEvidenceData();
        const body = {
            ...evidenceData,
            PersonalCode: PersonalCode
        };
        await PersonalCodeService.putValidatePersonalCodeRequest(emailId, body)
            .then(data => {
                setFilter({
                    PersonalCode: ""
                });
                setPersonalCodeModal(false);
                setLoadingPersonalCode(false);
                pendingSignature(emailId);
            })
            .catch(e => {
                setErrorPersonalCode(true);
                console.error(e);
                setFilter({
                    PersonalCode: ""
                });
                setTitlePersonalCodeModal(t('personal_code.error_title'));
                if (e.response.status === 409) {
                    setShowMessageMaximumPersonalCode(true);
                }
                setLoadingPersonalCode(false);
            });
    };

    return (
        <Modal
            title={titlePersonalCodeModal}
            footer={null}
            open={personalCodeModal}
            closable={false}
            className={`${errorPersonalCode ? 'error-title-personal-code' : ''}`}
        >
            <div className="modal-container">
                {!errorPersonalCode &&
                    <p className="nu-input-label">{t('personal_code.description')}</p>
                }
                {errorPersonalCode && !showMessageMaximumPersonalCode &&
                    <p className="nu-input-label error-message">{t('personal_code.error_description')}</p>
                }
                {showMessageMaximumPersonalCode &&
                    <p className="nu-input-label error-message">{t('sms.message_maximum_OTP')}</p>
                }
                <Form.Item>
                    <Input.Password status={`${errorPersonalCode ? 'error' : ''}`} className="nu-input" visibilityToggle={false} name="PersonalCode" value={PersonalCode} onChange={handlePersonalCodeChange} placeholder={t('personal_code.enter_the_code')} />
                </Form.Item>
                <Col className="action-buttons mobile-button">
                    <button type="button" className="btn btn-primary btn-active" onClick={handlePersonalCode} disabled={loadingPersonalCode || PersonalCode.length === 0}>
                        <span className="cancel-text">{t('general.enter')}</span>
                        <ArrowIcon />
                    </button>
                </Col>
            </div>
        </Modal>
    );
};