import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const FormAfterPreview = ({ documents, emailId }) => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate("/questions", {
            state: {
              documents,
              emailId
            }
          });
    }, []);

    return <></>;
}