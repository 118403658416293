import React, { useEffect, useState } from 'react';

export const DrawDocumentIcon = () => {

  const [color, setColor] = useState('');
  const DOMstyle = document.documentElement.style;

  // On Init
  useEffect(() => {
    const color = DOMstyle.getPropertyValue("--custom-secondary-background-color");
    setColor(color);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <svg className="card-img-top img-step" width="160" height="100" viewBox="0 0 180 176" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.3">
          <path d="M119.742 68.9829H116.776C116.407 68.9829 116.053 68.8362 115.792 68.5751C115.531 68.3141 115.385 67.96 115.385 67.5908C115.385 67.2216 115.531 66.8675 115.792 66.6064C116.053 66.3454 116.407 66.1987 116.776 66.1987H119.742C120.111 66.1987 120.465 66.3454 120.726 66.6064C120.987 66.8675 121.133 67.2216 121.133 67.5908C121.133 67.96 120.987 68.3141 120.726 68.5751C120.465 68.8362 120.111 68.9829 119.742 68.9829Z" fill={color} />
          <path d="M131.239 68.9829H127.901C127.533 68.9829 127.179 68.8362 126.918 68.5751C126.657 68.3141 126.511 67.96 126.511 67.5908C126.511 67.2216 126.657 66.8675 126.918 66.6064C127.179 66.3454 127.533 66.1987 127.901 66.1987H131.239C131.608 66.1987 131.962 66.3454 132.223 66.6064C132.484 66.8675 132.63 67.2216 132.63 67.5908C132.63 67.96 132.484 68.3141 132.223 68.5751C131.962 68.8362 131.608 68.9829 131.239 68.9829Z" fill={color} />
          <path d="M123.915 76.3144C123.546 76.3144 123.193 76.1678 122.932 75.9067C122.671 75.6456 122.524 75.2916 122.524 74.9224V71.9526C122.524 71.5834 122.671 71.2293 122.932 70.9683C123.193 70.7072 123.546 70.5605 123.915 70.5605C124.284 70.5605 124.638 70.7072 124.899 70.9683C125.159 71.2293 125.306 71.5834 125.306 71.9526V74.9224C125.306 75.2916 125.159 75.6456 124.899 75.9067C124.638 76.1678 124.284 76.3144 123.915 76.3144Z" fill={color} />
          <path d="M123.915 65.1778C123.546 65.1778 123.193 65.0312 122.932 64.7701C122.671 64.5091 122.524 64.155 122.524 63.7858V60.4448C122.524 60.0756 122.671 59.7215 122.932 59.4605C123.193 59.1994 123.546 59.0527 123.915 59.0527C124.284 59.0527 124.638 59.1994 124.899 59.4605C125.159 59.7215 125.306 60.0756 125.306 60.4448V63.7858C125.306 64.155 125.159 64.5091 124.899 64.7701C124.638 65.0312 124.284 65.1778 123.915 65.1778Z" fill={color} />
          <path d="M4.35768 118.734H1.39075C1.0219 118.734 0.668172 118.588 0.407356 118.327C0.146539 118.066 0 117.711 0 117.342C0 116.973 0.146539 116.619 0.407356 116.358C0.668172 116.097 1.0219 115.95 1.39075 115.95H4.35768C4.72653 115.95 5.08026 116.097 5.34108 116.358C5.60189 116.619 5.74843 116.973 5.74843 117.342C5.74843 117.711 5.60189 118.066 5.34108 118.327C5.08026 118.588 4.72653 118.734 4.35768 118.734Z" fill={color} />
          <path d="M15.8545 118.734H12.5167C12.1479 118.734 11.7941 118.588 11.5333 118.327C11.2725 118.066 11.126 117.711 11.126 117.342C11.126 116.973 11.2725 116.619 11.5333 116.358C11.7941 116.097 12.1479 115.95 12.5167 115.95H15.8545C16.2234 115.95 16.5771 116.097 16.8379 116.358C17.0987 116.619 17.2453 116.973 17.2453 117.342C17.2453 117.711 17.0987 118.066 16.8379 118.327C16.5771 118.588 16.2234 118.734 15.8545 118.734Z" fill={color} />
          <path d="M8.52942 126.066C8.16057 126.066 7.80684 125.919 7.54603 125.658C7.28521 125.397 7.13867 125.043 7.13867 124.674V121.704C7.13867 121.335 7.28521 120.981 7.54603 120.72C7.80684 120.459 8.16057 120.312 8.52942 120.312C8.89827 120.312 9.252 120.459 9.51282 120.72C9.77363 120.981 9.92017 121.335 9.92017 121.704V124.674C9.92017 125.043 9.77363 125.397 9.51282 125.658C9.252 125.919 8.89827 126.066 8.52942 126.066Z" fill={color} />
          <path d="M8.52942 114.93C8.16057 114.93 7.80684 114.783 7.54603 114.522C7.28521 114.261 7.13867 113.907 7.13867 113.538V110.197C7.13867 109.828 7.28521 109.473 7.54603 109.212C7.80684 108.951 8.16057 108.805 8.52942 108.805C8.89827 108.805 9.252 108.951 9.51282 109.212C9.77363 109.473 9.92017 109.828 9.92017 110.197V113.538C9.92017 113.907 9.77363 114.261 9.51282 114.522C9.252 114.783 8.89827 114.93 8.52942 114.93Z" fill={color} />
          <path d="M165.129 24.8159H162.162C161.793 24.8159 161.44 24.6692 161.179 24.4082C160.918 24.1471 160.771 23.793 160.771 23.4238C160.771 23.0546 160.918 22.7005 161.179 22.4395C161.44 22.1784 161.793 22.0317 162.162 22.0317H165.129C165.498 22.0317 165.852 22.1784 166.113 22.4395C166.373 22.7005 166.52 23.0546 166.52 23.4238C166.52 23.793 166.373 24.1471 166.113 24.4082C165.852 24.6692 165.498 24.8159 165.129 24.8159Z" fill={color} />
          <path d="M176.626 24.8159H173.288C172.919 24.8159 172.566 24.6692 172.305 24.4082C172.044 24.1471 171.897 23.793 171.897 23.4238C171.897 23.0546 172.044 22.7005 172.305 22.4395C172.566 22.1784 172.919 22.0317 173.288 22.0317H176.626C176.995 22.0317 177.349 22.1784 177.609 22.4395C177.87 22.7005 178.017 23.0546 178.017 23.4238C178.017 23.793 177.87 24.1471 177.609 24.4082C177.349 24.6692 176.995 24.8159 176.626 24.8159Z" fill={color} />
          <path d="M169.302 32.1474C168.933 32.1474 168.579 32.0008 168.318 31.7397C168.058 31.4787 167.911 31.1246 167.911 30.7554V27.7856C167.911 27.4164 168.058 27.0623 168.318 26.8013C168.579 26.5402 168.933 26.3936 169.302 26.3936C169.671 26.3936 170.024 26.5402 170.285 26.8013C170.546 27.0623 170.693 27.4164 170.693 27.7856V30.7554C170.693 31.1246 170.546 31.4787 170.285 31.7397C170.024 32.0008 169.671 32.1474 169.302 32.1474V32.1474Z" fill={color} />
          <path d="M169.302 21.0108C168.933 21.0108 168.579 20.8642 168.318 20.6031C168.058 20.3421 167.911 19.988 167.911 19.6188V16.2778C167.911 15.9086 168.058 15.5545 168.318 15.2935C168.579 15.0324 168.933 14.8857 169.302 14.8857C169.671 14.8857 170.024 15.0324 170.285 15.2935C170.546 15.5545 170.693 15.9086 170.693 16.2778V19.6188C170.693 19.988 170.546 20.3421 170.285 20.6031C170.024 20.8642 169.671 21.0108 169.302 21.0108V21.0108Z" fill={color} />
          <path d="M24.9494 150.845C23.7544 150.843 22.6089 150.367 21.7641 149.521C20.9193 148.675 20.4443 147.529 20.4434 146.332C20.4443 145.136 20.9193 143.99 21.7641 143.144C22.6089 142.298 23.7544 141.822 24.9494 141.82C26.1446 141.821 27.2907 142.297 28.1359 143.143C28.981 143.989 29.4563 145.136 29.4572 146.332C29.4558 147.529 28.9804 148.676 28.1353 149.521C27.2902 150.367 26.1445 150.843 24.9494 150.845V150.845ZM24.9494 144.604C24.4918 144.605 24.0532 144.787 23.7298 145.111C23.4065 145.435 23.2249 145.874 23.2249 146.332C23.2253 146.79 23.4071 147.229 23.7304 147.553C24.0536 147.877 24.492 148.06 24.9494 148.06C25.4071 148.06 25.846 147.878 26.1696 147.554C26.4933 147.23 26.6753 146.791 26.6757 146.332C26.676 146.105 26.6316 145.88 26.5449 145.671C26.4583 145.461 26.3311 145.27 26.1708 145.109C26.0105 144.949 25.8201 144.821 25.6105 144.734C25.4009 144.647 25.1762 144.603 24.9494 144.603V144.604Z" fill={color} />
          <path d="M173.288 63.5394C172.093 63.5379 170.947 63.062 170.102 62.2161C169.257 61.3703 168.782 60.2234 168.78 59.0272C168.781 57.8306 169.256 56.6833 170.102 55.8371C170.947 54.9908 172.093 54.5147 173.288 54.5132C174.483 54.5147 175.629 54.9905 176.474 55.8364C177.319 56.6823 177.795 57.8291 177.796 59.0254C177.795 60.2219 177.32 61.3692 176.475 62.2155C175.63 63.0618 174.484 63.5379 173.288 63.5394V63.5394ZM173.288 57.2992C172.831 57.3002 172.392 57.4825 172.069 57.8064C171.745 58.1302 171.563 58.5692 171.562 59.0272C171.563 59.4852 171.745 59.9242 172.069 60.248C172.392 60.5719 172.831 60.7542 173.288 60.7552C173.746 60.7542 174.184 60.5719 174.508 60.248C174.831 59.9242 175.014 59.4852 175.015 59.0272C175.014 58.5689 174.832 58.1294 174.509 57.8052C174.185 57.4809 173.746 57.2983 173.288 57.2973V57.2992Z" fill={color} />
          <path d="M102.584 33.3416C101.389 33.3401 100.243 32.8643 99.3981 32.0184C98.553 31.1725 98.0776 30.0257 98.0762 28.8294C98.0772 27.6329 98.5523 26.4856 99.3975 25.6393C100.243 24.793 101.389 24.3169 102.584 24.3154C103.779 24.3169 104.925 24.7928 105.77 25.6386C106.615 26.4845 107.09 27.6314 107.092 28.8276C107.091 30.0242 106.616 31.1715 105.771 32.0177C104.926 32.864 103.779 33.3401 102.584 33.3416ZM102.584 27.1014C102.127 27.1024 101.688 27.2848 101.364 27.6086C101.041 27.9325 100.859 28.3714 100.858 28.8294C100.859 29.2874 101.041 29.7264 101.364 30.0503C101.688 30.3741 102.127 30.5565 102.584 30.5575C103.042 30.5565 103.48 30.3741 103.804 30.0503C104.127 29.7264 104.309 29.2874 104.31 28.8294C104.31 28.3711 104.128 27.9317 103.804 27.6074C103.481 27.2832 103.042 27.1005 102.584 27.0996V27.1014Z" fill={color} />
          <path d="M142.971 19.3402C142.677 19.3402 142.391 19.2466 142.154 19.0729C139.478 17.1241 136.692 15.3318 133.809 13.7051C133.488 13.5241 133.252 13.2228 133.152 12.8675C133.053 12.5121 133.098 12.1318 133.279 11.81C133.46 11.4883 133.761 11.2517 134.117 11.1522C134.472 11.0526 134.852 11.0983 135.174 11.2792C138.149 12.9592 141.024 14.8097 143.785 16.8215C143.933 16.9291 144.058 17.0647 144.154 17.2207C144.249 17.3767 144.313 17.55 144.341 17.7306C144.369 17.9113 144.362 18.0957 144.319 18.2735C144.276 18.4513 144.199 18.6189 144.091 18.7667C143.963 18.9439 143.795 19.0882 143.6 19.1878C143.405 19.2875 143.19 19.3397 142.971 19.3402V19.3402Z" fill={color} />
          <path d="M66.3523 6.67825C66.0557 6.67822 65.767 6.58327 65.5281 6.40729C65.2892 6.23132 65.1129 5.98353 65.0247 5.70009C64.9704 5.52545 64.951 5.34181 64.9677 5.15969C64.9844 4.97756 65.0368 4.80051 65.1219 4.63866C65.207 4.4768 65.323 4.33332 65.4635 4.21639C65.604 4.09947 65.7662 4.0114 65.9407 3.95722C74.4068 1.32594 83.2219 -0.0082062 92.0868 3.79744e-05C99.0344 -0.00227229 105.958 0.815096 112.714 2.43523C113.071 2.52158 113.379 2.74565 113.572 3.05858C113.764 3.37151 113.825 3.74792 113.742 4.10571C113.655 4.46289 113.432 4.77144 113.119 4.96406C112.806 5.15669 112.43 5.21775 112.073 5.13397C105.529 3.56427 98.8232 2.77244 92.0942 2.77489C83.5104 2.76673 74.9748 4.05834 66.777 6.60586C66.64 6.65192 66.4968 6.67634 66.3523 6.67825V6.67825Z" fill={color} />
          <path d="M32.694 26.4066C32.5044 26.4069 32.3168 26.3683 32.1427 26.2933C31.9685 26.2183 31.8116 26.1084 31.6815 25.9704C31.4283 25.7021 31.2919 25.3441 31.3023 24.9751C31.3127 24.6062 31.4691 24.2565 31.7371 24.0029C33.1149 22.7037 34.5501 21.4304 36.0021 20.2239C36.1427 20.1072 36.305 20.0194 36.4796 19.9655C36.6542 19.9116 36.8378 19.8928 37.0197 19.91C37.2016 19.9272 37.3783 19.9803 37.5397 20.066C37.7011 20.1517 37.844 20.2684 37.9603 20.4095C38.0767 20.5504 38.1643 20.7129 38.2179 20.8876C38.2716 21.0624 38.2904 21.246 38.2732 21.428C38.256 21.61 38.203 21.7869 38.1175 21.9484C38.032 22.11 37.9156 22.2531 37.7748 22.3696C36.3655 23.5389 34.9748 24.7714 33.6415 26.0317C33.3844 26.2721 33.0458 26.4061 32.694 26.4066V26.4066Z" fill={color} />
          <path d="M6.2475 100.407C5.90919 100.406 5.58264 100.282 5.32903 100.058C5.07542 99.8339 4.9121 99.5249 4.8697 99.1889C4.40114 95.4772 4.1665 91.7397 4.16699 87.9986C4.16653 84.2754 4.39936 80.5559 4.86418 76.862C6.28262 65.5273 9.91498 54.5826 15.5544 44.6514C15.6442 44.4922 15.7646 44.3523 15.9085 44.2397C16.0524 44.1271 16.2171 44.044 16.3931 43.9952C16.5691 43.9465 16.7531 43.9329 16.9344 43.9553C17.1156 43.9778 17.2907 44.0358 17.4496 44.1261C17.6086 44.216 17.7483 44.3364 17.8608 44.4805C17.9733 44.6246 18.0563 44.7894 18.1051 44.9656C18.1538 45.1418 18.1674 45.3259 18.1449 45.5074C18.1225 45.6888 18.0645 45.864 17.9743 46.023C12.515 55.6373 8.99786 66.2325 7.6234 77.2054C7.17354 80.783 6.94817 84.3853 6.94849 87.9911C6.94797 91.615 7.17526 95.2354 7.62902 98.8307C7.65241 99.0121 7.63984 99.1963 7.59199 99.3728C7.54413 99.5493 7.46194 99.7146 7.35014 99.8593C7.23835 100.004 7.09909 100.125 6.94043 100.216C6.78178 100.307 6.60679 100.365 6.42551 100.388C6.36662 100.398 6.30717 100.404 6.2475 100.407V100.407Z" fill={color} />
          <path d="M48.8618 164.44C48.6184 164.441 48.3791 164.377 48.1683 164.255C44.7812 162.295 41.5278 160.112 38.4294 157.721C38.2847 157.61 38.1634 157.471 38.0725 157.312C37.9815 157.154 37.9226 156.979 37.8992 156.797C37.8758 156.616 37.8882 156.432 37.9359 156.255C37.9836 156.079 38.0656 155.913 38.1771 155.769C38.2887 155.624 38.4276 155.503 38.586 155.411C38.7444 155.32 38.9192 155.261 39.1003 155.238C39.2815 155.215 39.4655 155.227 39.6418 155.275C39.8181 155.323 39.9833 155.405 40.1279 155.516C43.1286 157.832 46.2792 159.945 49.559 161.844C49.7172 161.935 49.8558 162.057 49.9669 162.202C50.0781 162.347 50.1596 162.512 50.2068 162.689C50.254 162.865 50.2659 163.049 50.242 163.23C50.218 163.412 50.1586 163.586 50.0671 163.744C49.9452 163.956 49.7695 164.132 49.5578 164.254C49.3462 164.377 49.1061 164.441 48.8618 164.44V164.44Z" fill={color} />
          <path d="M92.0832 176C84.2792 176.006 76.509 174.974 68.9764 172.932C68.8001 172.884 68.6349 172.802 68.4903 172.69C68.3458 172.579 68.2247 172.44 68.134 172.281C68.0432 172.122 67.9845 171.947 67.9614 171.766C67.9382 171.585 67.9511 171.401 67.9991 171.224C68.0468 171.048 68.1287 170.883 68.2403 170.738C68.3519 170.593 68.491 170.472 68.6494 170.381C68.8079 170.29 68.9827 170.232 69.1638 170.208C69.3449 170.185 69.5289 170.198 69.7051 170.246C77.0003 172.223 84.5254 173.222 92.0832 173.216C98.2059 173.218 104.311 172.563 110.295 171.263C110.473 171.224 110.657 171.22 110.837 171.253C111.017 171.285 111.189 171.352 111.342 171.451C111.496 171.55 111.629 171.678 111.733 171.828C111.837 171.979 111.91 172.148 111.949 172.327C112.027 172.687 111.959 173.064 111.76 173.375C111.561 173.685 111.246 173.904 110.886 173.983C104.708 175.325 98.4048 176.002 92.0832 176V176Z" fill={color} />
          <path d="M134.497 164.899C134.251 164.9 134.008 164.834 133.795 164.709C133.582 164.585 133.406 164.406 133.285 164.19C133.104 163.868 133.059 163.488 133.158 163.133C133.257 162.777 133.494 162.476 133.815 162.295C135.894 161.122 137.95 159.845 139.923 158.499C140.074 158.397 140.244 158.325 140.423 158.288C140.602 158.251 140.787 158.25 140.966 158.284C141.146 158.319 141.317 158.388 141.469 158.489C141.622 158.589 141.753 158.719 141.856 158.871C141.958 159.022 142.03 159.192 142.067 159.371C142.104 159.55 142.106 159.735 142.071 159.915C142.037 160.094 141.967 160.265 141.866 160.418C141.766 160.571 141.636 160.702 141.485 160.805C139.445 162.195 137.324 163.513 135.18 164.725C134.971 164.84 134.736 164.9 134.497 164.899V164.899Z" fill={color} />
          <path d="M156.28 147.461C155.938 147.46 155.607 147.333 155.353 147.103C155.08 146.855 154.916 146.508 154.897 146.139C154.879 145.77 155.007 145.409 155.255 145.135C164.752 134.621 171.481 121.904 174.835 108.132C174.922 107.773 175.147 107.463 175.463 107.271C175.778 107.079 176.156 107.019 176.515 107.106C176.692 107.149 176.86 107.227 177.007 107.334C177.155 107.442 177.28 107.578 177.375 107.734C177.47 107.89 177.533 108.064 177.561 108.245C177.589 108.425 177.581 108.61 177.538 108.787C174.075 123.01 167.125 136.144 157.317 147.002C157.186 147.148 157.026 147.264 156.848 147.343C156.669 147.422 156.476 147.462 156.28 147.461Z" fill={color} />
          <path d="M178.61 89.3915C178.427 89.3917 178.246 89.3559 178.077 89.286C177.908 89.2161 177.755 89.1136 177.626 88.9843C177.496 88.855 177.394 88.7014 177.324 88.5324C177.254 88.3634 177.219 88.1823 177.219 87.9994C177.219 85.4362 177.104 82.8488 176.876 80.3115C176.69 78.2308 176.425 76.1371 176.09 74.0861C176.03 73.7222 176.117 73.3494 176.332 73.0498C176.547 72.7502 176.872 72.5481 177.236 72.488C177.416 72.4581 177.6 72.464 177.778 72.5054C177.956 72.5469 178.124 72.623 178.273 72.7296C178.421 72.8362 178.547 72.971 178.643 73.1264C178.74 73.2818 178.804 73.4546 178.834 73.6351C179.181 75.7529 179.455 77.9153 179.646 80.0628C179.882 82.6836 180 85.3545 180 87.9994C180 88.3686 179.854 88.7227 179.593 88.9837C179.332 89.2448 178.978 89.3915 178.61 89.3915V89.3915Z" fill={color} />
        </g>
        <path d="M57.165 70.0122H85.1655" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M57.165 80.5913H78.5826" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M94.8259 115.21V125.997C94.8259 127.769 94.1226 129.469 92.8707 130.722C91.6187 131.975 89.9208 132.679 88.1503 132.679H52.5286C51.6504 132.68 50.7805 132.508 49.9689 132.172C49.1573 131.836 48.4199 131.343 47.799 130.722C47.178 130.1 46.6856 129.362 46.3501 128.55C46.0146 127.737 45.8425 126.867 45.8438 125.988V47.6604C45.8425 46.7814 46.0146 45.9108 46.3501 45.0984C46.6856 44.286 47.178 43.5479 47.799 42.9263C48.4199 42.3047 49.1573 41.8119 49.9689 41.4761C50.7805 41.1403 51.6504 40.968 52.5286 40.9692H88.141C89.0185 40.9692 89.8874 41.1424 90.6979 41.4788C91.5084 41.8151 92.2447 42.3081 92.8647 42.9296C93.4847 43.5511 93.9763 44.2888 94.3112 45.1005C94.6461 45.9123 94.8178 46.7822 94.8166 47.6604V89.1576" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M94.8251 52.8984H45.8447" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M94.8251 120.707H45.8447" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M143.637 102.531H143.625C143.547 102.458 143.469 102.384 143.381 102.316C143.381 102.316 135.124 95.8522 131.44 93.559C131.084 93.3259 130.71 93.121 130.322 92.9462C128.753 92.1643 126.788 92.9462 125.807 94.1352C125.447 94.5544 125.19 95.051 125.056 95.5858C124.922 96.1206 124.915 96.6791 125.034 97.2172C122.964 95.7516 120.391 96.138 118.905 97.9603C118.461 98.5157 118.158 99.1683 118.019 99.8637C117.88 100.559 117.91 101.278 118.107 101.959C117.171 101.441 116.081 101.27 115.031 101.476C113.98 101.682 113.037 102.252 112.37 103.084C111.783 103.79 111.429 104.659 111.355 105.572C111.281 106.486 111.492 107.399 111.959 108.19L99.7087 98.3284C97.5505 96.5907 94.6078 96.6456 93.133 98.4519C91.6581 100.258 92.2126 103.132 94.3754 104.87L115.831 122.143C114.098 121.508 112.559 120.964 111.443 120.605C107.467 119.327 103.767 120.081 102.656 122.246C100.997 125.482 107.042 127.027 111.05 128.939C114.34 130.509 120.762 133.303 122.444 133.82C125.379 134.721 128.037 135.297 130.08 135.372C136.999 136.074 142.694 133.086 146.796 128.056C151.773 121.949 154.49 111.269 143.637 102.531Z" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M57.8722 106.206C61.6624 104.337 65.3767 100.957 67.1735 97.0812C67.8708 95.5741 68.364 93.7941 67.6723 92.1923C67.3904 91.5255 66.9159 90.9582 66.3096 90.5634C65.7033 90.1685 64.9929 89.964 64.2696 89.9761C61.1172 90.0708 58.5472 93.1927 57.7164 95.9972C56.5426 99.9637 58.8549 104.35 62.6137 105.983C66.6543 107.735 72.6883 105.35 73.5376 100.749C73.6915 99.908 73.0406 99.1767 72.1672 99.3772C71.151 99.6129 71.0046 100.743 71.2883 101.58C71.6419 102.556 72.2818 103.401 73.1241 104.006C75.8907 106.028 78.4886 103.598 79.7997 101.153L77.7599 100.888C78.5795 104.415 81.9006 106.208 85.3626 105.61C86.7701 105.365 86.173 103.219 84.7692 103.461C84.5411 103.501 85.1105 103.438 84.7934 103.461C84.6265 103.461 84.4577 103.488 84.289 103.492C83.7833 103.515 83.2767 103.47 82.7832 103.357C81.1867 102.976 80.2743 101.872 79.909 100.3C79.6939 99.3716 78.3217 99.2009 77.8693 100.032C77.2685 101.146 76.0928 103.188 74.5927 102.33C74.1945 102.088 73.8611 101.752 73.621 101.352C73.5308 101.207 73.4526 101.055 73.3874 100.897C73.2891 100.654 73.3874 100.803 73.3521 100.816C73.265 100.856 73.1166 101.443 72.7476 101.53L71.3773 100.159C70.8303 103.128 67.1123 104.892 64.3308 104.231C61.4362 103.542 59.1572 100.281 59.6745 97.3262C60.0454 95.2196 61.6865 92.7528 63.8968 92.2591C64.5588 92.1106 65.2412 92.2387 65.6195 93.0516C66.0646 94.0057 65.5732 95.2789 65.1485 96.1532C63.45 99.637 60.1808 102.588 56.7392 104.287C55.4541 104.921 56.5797 106.842 57.861 106.209L57.8722 106.206Z" fill={color} />
      </svg>
    </>
  );
}