import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import './BatchDocuments.css';
import { BatchDocumentItem } from '../../components/BatchDocumentItem/BatchDocumentItem';
import { useLocation } from 'react-router-dom';
import { BatchContext } from '../../contexts/BatchContext';
import { ArrowIcon } from '../../components/SvgComponents/ArrowIcon/ArrowIcon';
import { HandIcon } from '../../components/SvgComponents/HandIcon/HandIcon';
import { DisclaimerModal } from '../../components/Modals/DisclaimerModal/DisclaimerModal';
import { FIRST_LOAD } from '../../constants/GeneralConstants';

export const BatchDocuments = () => {
    const { batchDocuments, selectedBatchDocumentGuids, selectAllBatchDocuments, unselectAllBatchDocument } = useContext(BatchContext);
    const location = useLocation();
    const [openDisclaimerModal, setOpenDisclaimerModal] = useState(false);

    const { emailId } = location.state;
    const isBatchMultisignature = batchDocuments[0].SignerDTO.AllowBatchMultiSignature;

    const { t } = useTranslation();

    const signerName = batchDocuments[0].SignerDTO.SignerName;

    const handleSignAll = () => {
        setOpenDisclaimerModal(true);
    }

    useEffect(() => {
        // Reset first load to make AfterPreview work with batch
        localStorage.setItem(FIRST_LOAD, "true");
    }, []);

    return (
        <>
            <Container className="container-row row-box form-view">
                <Row>
                    <div className="welcome-container">
                        <HandIcon />
                        <div className="welcome-text-container">
                            <h2>{t("general.hello")} {signerName},</h2>
                            <p>{t("popup_process.pending_documents_1")}{" "} <b>{t("popup_process.pending_documents_2", { pending: batchDocuments.length })}</b>  {t("popup_process.pending_documents_3")}</p>
                        </div>
                    </div>
                    <hr className="welcome-separator" />
                    {isBatchMultisignature && <div className="select-buttons-container">
                        <button disabled={selectedBatchDocumentGuids.length === batchDocuments.length} className='select-all-button' onClick={selectAllBatchDocuments}>
                            {t("general.checkall")}
                        </button>
                        <button disabled={selectedBatchDocumentGuids.length === 0} className='select-all-button' onClick={unselectAllBatchDocument}>
                            {t("general.uncheckall")}
                        </button>
                    </div>}
                    <div className='documents-batch-grid'>
                        {
                            batchDocuments.map((document, i) => {
                                return <BatchDocumentItem isBatchMultisignature={isBatchMultisignature} document={document} key={i} emailId={emailId} />;
                            })
                        }
                    </div>
                    {isBatchMultisignature && <Row className="next-documents-buttons-container batch-button-container">
                            <button disabled={selectedBatchDocumentGuids.length === 0} type="button" className="btn btn-primary btn-active" onClick={handleSignAll}>
                                {t('general.sign')}
                                <ArrowIcon />
                            </button>
                    </Row>
                    }
                </Row>
            </Container>
            {openDisclaimerModal && <DisclaimerModal setOpenDisclaimerModal={setOpenDisclaimerModal} />}
        </>
    );
};