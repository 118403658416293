import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import './DocumentQuestions.css';
import { useLocation, useNavigate } from 'react-router-dom';
import Helper from '../../util/helper';
import { Question } from '../../components/Question/Question';
import { FormContext } from '../../contexts/FormContext';
import { useTranslation } from 'react-i18next';
import cancel from './../../assets/icons/cancel.svg';
import {
  FIRST_LOAD,
  IS_FROM_BATCH_LIST,
} from "../../constants/GeneralConstants";
import SignatureService from '../../services/SignatureService';
import { QuestionsErrorModal } from '../../components/Modals/QuestionsErrorModal/QuestionsErrorModal';
import { ArrowIcon } from '../../components/SvgComponents/ArrowIcon/ArrowIcon';
import { HandIcon } from '../../components/SvgComponents/HandIcon/HandIcon';
import { Back } from '../../components/Back/Back';

export const DocumentQuestions = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const document = location.state.documents;
  const emailId = location.state.emailId;

  const { form, setForm, formTransform, setFormTransform, cleanForm, areQuestionsAnswered } = useContext(FormContext);
  const [openQuestionErrorModal, setOpenQuestionErrorModal] = useState(false);

  const isFromBatchList = localStorage.getItem(IS_FROM_BATCH_LIST) === "true";

  useEffect(() => {
    setForm(document.SignerDTO.Form);
    const formData = document.SignerDTO.Form;

    const clonedForm = Helper.clone(formData.Sections);
    const transformedForm = Helper.transformFormData(clonedForm);
    setFormTransform(transformedForm);
  }, []);

  const handleCleanForm = () => {
    const form = cleanForm();
    setFormTransform(form);
  };

  const handleForm = () => {
    if (areQuestionsAnswered()) {
      const array = [].concat(...formTransform);
      let result = [].concat(...array.map(val => val.components.map(comp => {
        return { id: comp.id, response: (comp.response === undefined ? comp.selectedchoice : comp.response) };
      })));
      result = result.filter(val => val.response !== undefined);
      const signerGuid = document.SignerDTO.SignerGUI;
      setLoading(true);
      SignatureService.signatureFormResponse(signerGuid, form, result, false)
        .then(response => {
          const link = "/document";
          window.scrollTo(0, 0);
          navigate(link, {
            state: {
              documents: document,
              emailId: emailId,
              isFromQuestion: true
            }
          });
          localStorage.setItem(FIRST_LOAD, "false")
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setLoading(false);
        })
      return;
    }

    setOpenQuestionErrorModal(true);
  };

  const handleBack = () => {
    let link = "/document"

    navigate(link, {
      state: {
        documents: document,
        emailId: emailId,
      }
    });
  };

  const isAfterPreview = () => {
    return document.SignerDTO.Form.FormDisplay === "AfterPreview";
  }

  const checkBackNavigation = () => {
    return (isAfterPreview() || isFromBatchList);
  }

  return (
    <>
      <Container className="container-row row-box form-view">
        <Row>
          <Col md={12} lg={12} className="document-preview-title" style={{paddingLeft: '0px'}}>
            {checkBackNavigation() &&
              <Back handleBack={handleBack} />
            }
          </Col>
          <div className="welcome-container" style={{paddingLeft: '0px'}}>
            {!checkBackNavigation() && <HandIcon />}
            <div className="welcome-text-container">
              <h2>{t("general.hello")} {document?.SignerDTO?.SignerName},</h2>
              <p>{t('question.complete_information')}</p>
            </div>
          </div>
          <hr className="welcome-separator" />
          {formTransform && <Question />}
        </Row>
        <Row className="action-buttons">
          <Col md={1} lg={1} xs={0} sm={0}></Col>
          <Col md={5} lg={5} xs={6} sm={6}>
            <button type="button" className="btn btn-secondary btn-cancel" onClick={handleCleanForm}>
              <span className="cancel-text"> {t('sign.erase')} </span>
              <img className="icon-image" src={cancel} alt="Cancel" />
            </button>
          </Col>
          <Col md={5} lg={5} xs={6} sm={6}>
            <button type="button" className="btn btn-primary btn-active" onClick={handleForm} disabled={loading}>
              <span className="cancel-text"> {t('general.continue')} </span>
              <ArrowIcon />
            </button>
          </Col>
          <Col md={1} lg={1} xs={1} sm={1}></Col>
        </Row>
      </Container>
      <QuestionsErrorModal setOpenQuestionErrorModal={setOpenQuestionErrorModal} openQuestionErrorModal={openQuestionErrorModal} />
    </>
  );
};
