import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./SignatureDelegation.css";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from "react";
import SignatureDelegationService from "../../services/SignatureDelegationService";
import cancel from './../../assets/icons/cancel.svg';
import { ArrowIcon } from "../../components/SvgComponents/ArrowIcon/ArrowIcon";

export const SignatureDelegation = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { Form } = require("react-bootstrap");
  const { register, formState: { errors }, handleSubmit } = useForm();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const signerGUI = location.state.documents.SignerDTO.SignerGUI;
  const documents = location.state.documents;
  const emailId = location.state.emailId;
  const defaultMessage = location.state.signatureDelegationInfo.Message;
  const typeOfDocuments = ["DNI", "PASSPORT", "NIF", "NIE"];

  const sendRequestPersonalData = (data) => {
    setLoading(true);
    setShowErrorMessage(false);

    const body = {
      Email: data.email,
      Message: data.message,
      Name: data.name,
      TypeOfID: data.typeOfID,
      NumberId: data.numberOfID,
      PhoneNumber: data.phone,
    };

    SignatureDelegationService.signatureDelegation(signerGUI, body)
      .then(response => {
        const link = "/finalized-process";
        navigate(link);
      })
      .catch(err => {
        console.log(err);
        setErrorMessage(t('general.error_modal'));
        if (err.response.data.error.includes('Signer NumberID is empty')) {
          setErrorMessage(t("signature_delegation.signer_number_id_is_empty"));
        }
        if (err.response.data.error.includes('Signer TypeOfID is empty')) {
          setErrorMessage(t("signature_delegation.signer_type_of_id_is_empty"));
        }
        if (err.response.data.error.includes('Signer Name contains invalid characters')) {
          setErrorMessage(t("signature_delegation.signer_name_invalid_format"));
        }
        if (err.response.data.error.includes('Signer ID Number must be alphanumeric')) {
          setErrorMessage(t("signature_delegation.signer_name_invalid_format"));
        }
        setLoading(false);
        setShowErrorMessage(true);
      });
  }

  const handleBack = () => {
    let link = "/document"

    navigate(link, {
      state: {
        documents: documents,
        emailId: emailId
      }
    });
  };


  return (
    <Container className="container-row row-box form-view">
      <Row>
        <div className="welcome-container">
          <div className="welcome-text-container">
            <h3>{t('signature_delegation.title')}</h3>
            <strong><p>{t('signature_delegation.description')}</p></strong>
          </div>
        </div>
        <hr className="welcome-separator" />
      </Row>
      <Row>
        <Form onSubmit={handleSubmit(sendRequestPersonalData)}>
          <div className="textbox-container">
            <label> {t('signature_delegation.email')} *</label>
            <Form.Control
              type="text"
              {...register('email', {
                required: true,
                pattern: {
                  value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                },
              })}
            />
            {errors.email?.type === 'required' && <p className="required-field"> {t('signer_data.required_field')} </p>}
            {errors.email?.type === 'pattern' && <p className="required-field"> {t('signature_delegation.email_invalid_format')} </p>}
          </div>
          <div className="textbox-container">
            <label> {t('signature_delegation.message')} *</label>
            <Form.Control
              type="text"
              {...register('message', { value: defaultMessage, required: true })}
            />
            {errors.message?.type === 'required' && <p className="required-field"> {t('signer_data.required_field')} </p>}
          </div>
          <div className="textbox-container">
            <label>{t('signer_data.name')}</label>
            <Form.Control
              type="text"
              {...register('name')}
            />
          </div>
          <div className="textbox-container">
            <label>{t('signer_data.document_type')}</label>
            <Form.Select className="text-center"
              {...register('typeOfID')}
            >
              <option value="" defaultValue >{t('signer_data.select')}</option>
              {typeOfDocuments.map(type =>
                <option key={type} value={type}>{type}</option>
              )};
            </Form.Select>
          </div>
          <div className="textbox-container">
            <label>{t('signer_data.number_id')}</label>
            <Form.Control
              type="text"
              {...register('numberOfID')}
            />
          </div>
          {showErrorMessage &&
            <div className="text-align-start">
              <span className="required-field"> {errorMessage} </span>
            </div>
          }
          <Row className="delegation-button action-buttons mobile-button">
            <Col md={6} lg={6} xs={6}>
              <button type="button" className="btn btn-secondary btn-cancel btn-mobile" onClick={() => handleBack()}>
                <span className="cancel-text">{t('general.cancel')}</span>
                <img className="icon-image" src={cancel} alt="Cancel" />
              </button>
            </Col>
            <Col md={6} lg={6} xs={6}>
              <button type="submit" className="btn btn-primary btn-active" disabled={loading}>
                <span className="cancel-text">{t('signature_delegation.delegate')}</span>
                <ArrowIcon />
              </button>
            </Col>
          </Row>
        </Form>
      </Row>
    </Container >
  );
};