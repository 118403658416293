import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Loading } from './Loading/Loading';
import { Home } from './Home/Home';
import { DocumentPreview } from './DocumentPreview/DocumentPreview';
import { BatchDocuments } from './BatchDocuments/BatchDocuments';
import { SendCustomSms } from './SendCustomSms/SendCustomSms';
import { SendOtpSms } from './SendOtpSms/SendOtpSms';
import { SendOtpCall } from './SendOtpCall/SendOtpCall';
import { Support } from './Support/Sopport';
import { DocumentQuestions } from './DocumentQuestions/DocumentQuestions';
import { OnPreviewPage } from './OnPreview/OnPreview';
import { FormContextProvider } from '../contexts/FormContext';
import { RejectedSuccess } from './RejectedSuccess/RejectedSuccess';
import { DocumentSign } from './DocumentSign/DocumentSign';
import { SignaturePanelView } from './SignaturePanel/SignaturePanel';
import { SignatureSuccess } from './SignatureSuccess/SignatureSuccess';
import { AttachImages } from './AttachImages/AttachImages';
import { SignerData } from './SignerData/SignerData';
import { SignatureDelegation } from './SignatureDelegation/SignatureDelegation';
import { FinalizedProcess } from './FinalizedProcess/FinalizedProcess';
import { BatchContextProvider } from '../contexts/BatchContext';
import { PendingDocuments } from './PendingDocuments/PendingDocuments';
import { Auth } from './Auth/Auth';
import { DocumentError } from './DocumentError/DocumentError';
import { ExpiredPersonalCode } from './ExpiredPersonalCode/ExpiredPersonalCode';
import { CriiptoCallback } from './CriiptoCallback/CriiptoCallback';
import PrivateRoute from './PrivateRoute';
import { AuthContextProvider } from '../contexts/AuthContext';
import { URLReturnContextProvider } from '../contexts/URLReturnContext';
import { RenderScrollContextProvider } from '../contexts/RenderScrollContext';
import { LangProvider } from '../contexts/LangContext';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <LangProvider>
          <AuthContextProvider>
            <URLReturnContextProvider>
              <BatchContextProvider>
                <RenderScrollContextProvider>
                  <Routes>
                    <Route path="/loading" element={<Loading />} />
                    <Route path="/home" element={<PrivateRoute element={<Home />} />} />
                    <Route path="/document" element={<PrivateRoute element={<DocumentPreview />} />} />
                    <Route path="/documents" element={<PrivateRoute element={<BatchDocuments />} />} />
                    <Route path="/attach-images" element={<PrivateRoute element={<AttachImages />} />} />
                    <Route path="/customsms" element={<PrivateRoute element={<SendCustomSms />} />} />
                    <Route path="/sms" element={<PrivateRoute element={<SendOtpSms />} />} />
                    <Route path="/call" element={<PrivateRoute element={<SendOtpCall />} />} />
                    <Route path="/support" element={<PrivateRoute element={<Support />} />} />
                    <Route path="/document-questions" element={
                      <PrivateRoute element={
                        <FormContextProvider>
                          <OnPreviewPage />
                        </FormContextProvider>
                      } />
                    } />
                    <Route path="/questions" element={
                      <PrivateRoute element={
                        <FormContextProvider>
                          <DocumentQuestions />
                        </FormContextProvider>
                      } />
                    } />
                    <Route path="/rejected-success" element={<PrivateRoute element={<RejectedSuccess />} />} />
                    <Route path="/signature-success" element={<PrivateRoute element={<SignatureSuccess />} />} />
                    <Route path="/sign" element={<PrivateRoute element={<DocumentSign />} />} />
                    <Route path="/signer-data" element={<PrivateRoute element={<SignerData />} />} />
                    <Route path="/signature-delegation" element={<PrivateRoute element={<SignatureDelegation />} />} />
                    <Route path="/finalized-process" element={<PrivateRoute element={<FinalizedProcess />} />} />
                    <Route path="/pending-documents" element={<PrivateRoute element={<PendingDocuments />} />} />
                    <Route path="/signaturepanel" element={<SignaturePanelView />} />
                    <Route path="/auth" element={<Auth />} />
                    <Route path="/ssi" element={<Auth />} />
                    <Route path="/document-error" element={<DocumentError />} />
                    <Route path="/expired-personal-code" element={<ExpiredPersonalCode />} />
                    <Route path="/criipto/bankid" element={<CriiptoCallback />} />
                    <Route path="*" element={<Loading />} />
                  </Routes>
                </RenderScrollContextProvider>
              </BatchContextProvider>
            </URLReturnContextProvider>
          </AuthContextProvider>
        </LangProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;