import "./Question.css";
import { TextBox } from "../TextBox/TextBox";
import { RadioButton } from "../RadioButton/RadioButton";
import { Checkbox } from "../Checkbox/Checkbox";
import { Attachment } from "../Attachment/Attachment";
import { Fragment, useContext } from "react";
import { FormTitle } from "../FormTitle/FormTitle";
import {
  ATTACHMENT,
  CHECKBOX,
  RADIOBUTTON,
  TEXTBOX,
} from "../../constants/GeneralConstants";
import { FormContext } from "../../contexts/FormContext";
import Helper from "../../util/helper";

export const Question = () => {
  const { formTransform, setFormTransform, checkDisabled } = useContext(FormContext);

  const sections = formTransform.map((section, i) => {
    const groups = section.map((group, j) => {
      const elements = group.components.map((component, k) => {

        const onUpdateValue = (newResponse) => {
          let existError = false;
          if (newResponse === undefined || newResponse === '' || newResponse?.fileinbase64 === null) {
            existError = true;
          }
          if (component.type === TEXTBOX) {
            if (!component.optional && !checkDisabled(component) && component.response === "") {
              existError = true;
            } else {
              existError = false;
            }
          }
          component.error = existError;
          component.response = newResponse;
          if (component.type === RADIOBUTTON) {
            component.selectedchoice = newResponse;
          }
          const clonedForm = Helper.clone(formTransform);
          clonedForm[i][j]["components"][k] = component;

          setFormTransform(clonedForm);
        };

        return (
          <Fragment key={k}>
            {component.type === TEXTBOX && (
              <TextBox
                key={k}
                componentData={component}
                onUpdateValue={onUpdateValue}
              />
            )}
            {component.type === CHECKBOX &&
              component.appearance === RADIOBUTTON && (
                <Checkbox
                  key={k}
                  onUpdateValue={onUpdateValue}
                  componentData={component}
                  appearance="radiobutton"
                />
              )}
            {component.type === CHECKBOX &&
              component.appearance === CHECKBOX && (
                <Checkbox
                  key={k}
                  onUpdateValue={onUpdateValue}
                  componentData={component}
                  appearance="checkbox"
                />
              )}
            {component.type === RADIOBUTTON && (
              <RadioButton
                key={k}
                componentData={component}
                onUpdateValue={onUpdateValue}
              />
            )}
            {component.type === ATTACHMENT && (
              <Attachment
                key={k}
                componentData={component}
                onUpdateValue={onUpdateValue}
              />
            )}
          </Fragment>
        );
      });
      return (
        <Fragment key={j}>
          <FormTitle title={group.title}></FormTitle>
          {elements}
        </Fragment>
      );
    });

    return groups;
  });

  return sections;
};
