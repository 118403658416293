import { Modal } from "antd";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import cancel from './../../../assets/icons/cancel.svg';
import { CheckIcon } from "../../SvgComponents/CheckIcon/CheckIcon";
import { useEffect, useState } from "react";
import { IS_CUSTOM } from "../../../constants/GeneralConstants";
import documentLoadingGif from './../../../assets/images/logo_vidsigner_green.gif';
import whiteLoaderGif from '../../../assets/images/white_loader.gif';

export const ConfirmationModalSignature = ({ showConfirmationModalSignature, setShowConfirmationModalSignature, handleSignature, sendingSignPetition, loadingOneShotSign, setOTPDraw, setLoadingMorePages }) => {
    const { t } = useTranslation();
    const [isCustom, setIsCustom] = useState(false);

    // On Init
    useEffect(() => {
        const isCustom = localStorage.getItem(IS_CUSTOM) === "true";
        setIsCustom(isCustom);
        if (setLoadingMorePages) setLoadingMorePages(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClose = () => {
        setShowConfirmationModalSignature(false);
        if (setOTPDraw) setOTPDraw(false)
    }
    return (
        <Modal
            title={t('general.confirm')}
            footer={null}
            open={showConfirmationModalSignature}
            closable={true}
            onCancel={handleClose}
        >
            <div className="modal-container">
                {!sendingSignPetition &&
                    <>
                        <p className="nu-input-label">{t('general.description_modal_confirm')}</p>
                        <Row className="action-buttons mobile-button">
                            <Col md={6} xs={6}>
                                <button type="button" className="btn btn-secondary btn-cancel" onClick={handleClose}>
                                    <span className="cancel-text">{t("general.cancel")}</span>
                                    <img className="icon-image" src={cancel} alt="Check" />
                                </button>
                            </Col>
                            <Col md={6} xs={6}>
                                <button type="button" className="btn btn-primary btn-active" onClick={handleSignature}>
                                    <span className="cancel-text">{t('general.confirm')}</span>
                                    <CheckIcon />
                                </button>
                            </Col>
                        </Row>
                    </>
                }
                {loadingOneShotSign &&
                    <span>{t('sign.one_shot.signature_waiting_message')} </span>
                }
                {sendingSignPetition &&
                    <div className="loader-container">
                        <img src={isCustom ? whiteLoaderGif : documentLoadingGif} alt="LoadingGif" />
                    </div>
                }
            </div>
        </Modal>
    );
};

