import { useContext, useEffect } from "react"
import { getEvidenceData } from "../../../util/getEvidenceData";
import EmailSignatureService from "../../../services/EmailSignatureService";
import { useNavigate } from "react-router-dom";
import { BatchContext } from "../../../contexts/BatchContext";
import PendingDocumentService from "../../../services/PendingDocumentService";
import { FIRST_LOAD } from "../../../constants/GeneralConstants";

export const SimplifiedSignature = ({ signerGuid, setLoadingMorePages, documents, emailId, email }) => {
    const navigate = useNavigate();
    const { remainingBatchDocuments, removeDocumentsFromBatch, batchAttachedImages } = useContext(BatchContext);

    useEffect(() => {
        const sendSimplifiedSignature = async () => {
            const evidenceData = await getEvidenceData();

            const body = {
                SignatureImage: '',
                SignatureRawData: '',
                OTP: '',
                ...evidenceData,
            };

            if (documents.SignerDTO.AttachIdDocImages && typeof attachedImages === 'undefined' && !batchAttachedImages) {
                return navigate("/attach-images", {
                    state: {
                        documents,
                        emailId,
                        otpCode: null
                    }
                });
            }

        
            EmailSignatureService.sendSign(signerGuid, body)
                .then(() => {
                    setLoadingMorePages(false);
                    sessionStorage.setItem("SIGNED_DOC", true);
                    localStorage.setItem(FIRST_LOAD, "true");

                    if (remainingBatchDocuments > 0) {
                        navigate('/documents', {
                            state: {
                            emailId,
                            }
                        })
                        return;
                    }
                    
                    PendingDocumentService.getPendingDocumentsList(emailId)
                    .then(res => {
                        const pendingDocuments = res.data.data;
                        setLoadingMorePages(false);
                        navigate("/signature-success", {
                        state: {
                            pendingDocuments,
                            emailId,
                            email
                        }
                        });
                    })
                    .catch(err => {
                        setLoadingMorePages(false);
                        navigate("/signature-success", {
                            state: {
                                pendingDocuments: [],
                                emailId,
                                email
                            }
                        });
                    });
                })
                .catch(err => {
                    setLoadingMorePages(false);
                    console.log(err);
                });

            return;
        }

        sendSimplifiedSignature();
        
    }, []);

    return <></>;
}