import { Col, Row } from "react-bootstrap";

import cancel from "./../../../assets/icons/cancel.svg";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import EmailSignatureService from "../../../services/EmailSignatureService";
import { useState } from "react";
import { ArrowIcon } from "../../SvgComponents/ArrowIcon/ArrowIcon";
import { isMobile } from "react-device-detect";
import { useOrientation } from "../../../hooks/useOrientation";

export const AOCValidModal = ({
  openAOCValidModal,
  setOpenAOCValidModal,
  signerGuid,
  setParentLoading,
}) => {
  const { isPortrait } = useOrientation();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleContinueAOCValid = () => {
    setLoading(true);
    EmailSignatureService.getAOCValidUrl(signerGuid)
    .then((response) => {
        const { Url } = response.data.data;
        
        window.location.href = Url;
    });
  }

  const handleCancel = () => {
    setOpenAOCValidModal(false);

    setParentLoading(false);
  }

  return (
    <Modal
      title=""
      style={{ top: 150 }}
      footer={null}
      open={openAOCValidModal}
      closable={true}
      onCancel={handleCancel}
    >
      <div className="modal-container">
        <p>{t("sign.auth_provider_title", { provider : 'AOCValid'})}</p>
        <p className="nu-input-label">{t("sign.auth_provider_description", { provider : 'AOCValid' })}</p>

        <Col className="modal-action-buttons">
          <Row>
            <Col md={5} xs={6}>
              <button
                disabled={loading}
                type="button"
                className="btn btn-primary btn-active"
                onClick={handleContinueAOCValid}
              >
                <Row>
                  <Col>{t("general.continue")}</Col>
                  <Col>
                    <ArrowIcon/>
                  </Col>
                </Row>
              </button>
            </Col>
            {!(isMobile && isPortrait) && <Col md={2} xs={0} sm={0}></Col>}
            <Col md={5} xs={6}>
              <button
                type="button"
                className="btn btn-secondary btn-cancel"
                onClick={handleCancel}
              >
                <Row>
                  <Col>{t("general.cancel")}</Col>
                  <Col>
                    <img className="icon-image" src={cancel} alt="Check" />
                  </Col>
                </Row>
              </button>
            </Col>
            <Col md={3} xs={3}></Col>
          </Row>
        </Col>
      </div>
    </Modal>
  );
};
