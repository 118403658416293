import React, { useEffect, useState } from 'react';

export const ViewDocumentIcon = () => {

  const [color, setColor] = useState('');
  const DOMstyle = document.documentElement.style;

  // On Init
  useEffect(() => {
    const color = DOMstyle.getPropertyValue("--custom-secondary-background-color");
    setColor(color);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <svg className="card-img-top img-step" width="160" height="100" viewBox="0 0 180 176" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.3">
          <path d="M4.35767 118.734H1.39075C1.0219 118.734 0.66817 118.588 0.407355 118.327C0.146539 118.066 0 117.711 0 117.342C0 116.973 0.146539 116.619 0.407355 116.358C0.66817 116.097 1.0219 115.95 1.39075 115.95H4.35767C4.72652 115.95 5.08025 116.097 5.34106 116.358C5.60188 116.619 5.74842 116.973 5.74842 117.342C5.74842 117.711 5.60188 118.066 5.34106 118.327C5.08025 118.588 4.72652 118.734 4.35767 118.734Z" fill={color} />
          <path d="M15.8545 118.734H12.5167C12.1479 118.734 11.7941 118.588 11.5333 118.327C11.2725 118.066 11.126 117.711 11.126 117.342C11.126 116.973 11.2725 116.619 11.5333 116.358C11.7941 116.097 12.1479 115.95 12.5167 115.95H15.8545C16.2234 115.95 16.5771 116.097 16.8379 116.358C17.0987 116.619 17.2453 116.973 17.2453 117.342C17.2453 117.711 17.0987 118.066 16.8379 118.327C16.5771 118.588 16.2234 118.734 15.8545 118.734Z" fill={color} />
          <path d="M8.52942 126.066C8.16057 126.066 7.80684 125.919 7.54603 125.658C7.28521 125.397 7.13867 125.043 7.13867 124.674V121.704C7.13867 121.335 7.28521 120.981 7.54603 120.72C7.80684 120.459 8.16057 120.312 8.52942 120.312C8.89827 120.312 9.25199 120.459 9.51281 120.72C9.77363 120.981 9.92017 121.335 9.92017 121.704V124.674C9.92017 125.043 9.77363 125.397 9.51281 125.658C9.25199 125.919 8.89827 126.066 8.52942 126.066Z" fill={color} />
          <path d="M8.52942 114.929C8.16057 114.929 7.80684 114.782 7.54603 114.521C7.28521 114.26 7.13867 113.906 7.13867 113.537V110.196C7.13867 109.827 7.28521 109.472 7.54603 109.211C7.80684 108.95 8.16057 108.804 8.52942 108.804C8.89827 108.804 9.25199 108.95 9.51281 109.211C9.77363 109.472 9.92017 109.827 9.92017 110.196V113.537C9.92017 113.906 9.77363 114.26 9.51281 114.521C9.25199 114.782 8.89827 114.929 8.52942 114.929Z" fill={color} />
          <path d="M116.545 151.402H113.578C113.209 151.402 112.856 151.255 112.595 150.994C112.334 150.733 112.188 150.379 112.188 150.01C112.188 149.641 112.334 149.286 112.595 149.025C112.856 148.764 113.209 148.618 113.578 148.618H116.545C116.914 148.618 117.268 148.764 117.529 149.025C117.789 149.286 117.936 149.641 117.936 150.01C117.936 150.379 117.789 150.733 117.529 150.994C117.268 151.255 116.914 151.402 116.545 151.402Z" fill={color} />
          <path d="M128.041 151.402H124.703C124.334 151.402 123.981 151.255 123.72 150.994C123.459 150.733 123.312 150.379 123.312 150.01C123.312 149.641 123.459 149.286 123.72 149.025C123.981 148.764 124.334 148.618 124.703 148.618H128.041C128.41 148.618 128.764 148.764 129.024 149.025C129.285 149.286 129.432 149.641 129.432 150.01C129.432 150.379 129.285 150.733 129.024 150.994C128.764 151.255 128.41 151.402 128.041 151.402Z" fill={color} />
          <path d="M120.717 158.732C120.348 158.732 119.994 158.586 119.734 158.325C119.473 158.064 119.326 157.71 119.326 157.34V154.371C119.326 154.001 119.473 153.647 119.734 153.386C119.994 153.125 120.348 152.979 120.717 152.979C121.086 152.979 121.439 153.125 121.7 153.386C121.961 153.647 122.108 154.001 122.108 154.371V157.34C122.108 157.71 121.961 158.064 121.7 158.325C121.439 158.586 121.086 158.732 120.717 158.732Z" fill={color} />
          <path d="M120.717 147.596C120.348 147.596 119.994 147.449 119.734 147.188C119.473 146.927 119.326 146.573 119.326 146.204V142.863C119.326 142.494 119.473 142.139 119.734 141.878C119.994 141.617 120.348 141.471 120.717 141.471C121.086 141.471 121.439 141.617 121.7 141.878C121.961 142.139 122.108 142.494 122.108 142.863V146.204C122.108 146.573 121.961 146.927 121.7 147.188C121.439 147.449 121.086 147.596 120.717 147.596Z" fill={color} />
          <path d="M165.129 24.8159H162.162C161.793 24.8159 161.44 24.6692 161.179 24.4082C160.918 24.1471 160.771 23.793 160.771 23.4238C160.771 23.0546 160.918 22.7005 161.179 22.4395C161.44 22.1784 161.793 22.0317 162.162 22.0317H165.129C165.498 22.0317 165.852 22.1784 166.113 22.4395C166.373 22.7005 166.52 23.0546 166.52 23.4238C166.52 23.793 166.373 24.1471 166.113 24.4082C165.852 24.6692 165.498 24.8159 165.129 24.8159Z" fill={color} />
          <path d="M176.625 24.8159H173.287C172.918 24.8159 172.565 24.6692 172.304 24.4082C172.043 24.1471 171.896 23.793 171.896 23.4238C171.896 23.0546 172.043 22.7005 172.304 22.4395C172.565 22.1784 172.918 22.0317 173.287 22.0317H176.625C176.994 22.0317 177.348 22.1784 177.608 22.4395C177.869 22.7005 178.016 23.0546 178.016 23.4238C178.016 23.793 177.869 24.1471 177.608 24.4082C177.348 24.6692 176.994 24.8159 176.625 24.8159Z" fill={color} />
          <path d="M169.302 32.1474C168.933 32.1474 168.579 32.0008 168.318 31.7397C168.058 31.4787 167.911 31.1246 167.911 30.7554V27.7856C167.911 27.4164 168.058 27.0623 168.318 26.8013C168.579 26.5402 168.933 26.3936 169.302 26.3936C169.671 26.3936 170.024 26.5402 170.285 26.8013C170.546 27.0623 170.693 27.4164 170.693 27.7856V30.7554C170.693 31.1246 170.546 31.4787 170.285 31.7397C170.024 32.0008 169.671 32.1474 169.302 32.1474Z" fill={color} />
          <path d="M169.302 21.0108C168.933 21.0108 168.579 20.8642 168.318 20.6031C168.058 20.3421 167.911 19.988 167.911 19.6188V16.2778C167.911 15.9086 168.058 15.5545 168.318 15.2935C168.579 15.0324 168.933 14.8857 169.302 14.8857C169.671 14.8857 170.024 15.0324 170.285 15.2935C170.546 15.5545 170.693 15.9086 170.693 16.2778V19.6188C170.693 19.988 170.546 20.3421 170.285 20.6031C170.024 20.8642 169.671 21.0108 169.302 21.0108Z" fill={color} />
          <path d="M24.9504 150.845C23.7554 150.843 22.6099 150.367 21.7651 149.521C20.9203 148.675 20.4453 147.529 20.4443 146.332C20.4453 145.136 20.9203 143.99 21.7651 143.144C22.6099 142.298 23.7554 141.822 24.9504 141.82C26.1456 141.821 27.2916 142.297 28.1368 143.143C28.982 143.989 29.4573 145.136 29.4582 146.332C29.4568 147.529 28.9813 148.676 28.1363 149.521C27.2912 150.367 26.1455 150.843 24.9504 150.845ZM24.9504 144.604C24.4928 144.605 24.0542 144.787 23.7308 145.111C23.4075 145.435 23.2258 145.874 23.2258 146.332C23.2263 146.79 23.4081 147.229 23.7314 147.553C24.0546 147.877 24.493 148.06 24.9504 148.06C25.4081 148.06 25.8469 147.878 26.1705 147.554C26.4942 147.23 26.6763 146.791 26.6768 146.332C26.677 146.105 26.6325 145.88 26.5459 145.671C26.4592 145.461 26.3321 145.27 26.1717 145.109C26.0114 144.949 25.821 144.821 25.6114 144.734C25.4018 144.647 25.1772 144.603 24.9504 144.603V144.604Z" fill={color} />
          <path d="M173.288 63.5398C172.093 63.5384 170.947 63.0625 170.102 62.2166C169.257 61.3708 168.782 60.2239 168.78 59.0277C168.781 57.8311 169.256 56.6838 170.102 55.8375C170.947 54.9913 172.093 54.5151 173.288 54.5137C174.483 54.5151 175.629 54.991 176.474 55.8369C177.319 56.6828 177.795 57.8296 177.796 59.0258C177.795 60.2224 177.32 61.3697 176.475 62.216C175.63 63.0622 174.484 63.5384 173.288 63.5398ZM173.288 57.2997C172.831 57.3006 172.392 57.483 172.069 57.8069C171.745 58.1307 171.563 58.5697 171.562 59.0277C171.563 59.4857 171.745 59.9246 172.069 60.2485C172.392 60.5724 172.831 60.7547 173.288 60.7557C173.746 60.7547 174.184 60.5724 174.508 60.2485C174.831 59.9246 175.014 59.4857 175.015 59.0277C175.014 58.5694 174.832 58.1299 174.509 57.8057C174.185 57.4814 173.746 57.2988 173.288 57.2978V57.2997Z" fill={color} />
          <path d="M142.97 19.3402C142.676 19.3402 142.39 19.2466 142.153 19.0729C139.477 17.1241 136.691 15.3318 133.808 13.7051C133.487 13.5241 133.251 13.2228 133.151 12.8675C133.052 12.5121 133.097 12.1318 133.278 11.81C133.459 11.4883 133.76 11.2517 134.116 11.1522C134.471 11.0526 134.851 11.0983 135.173 11.2792C138.148 12.9592 141.023 14.8097 143.784 16.8215C143.932 16.9291 144.057 17.0647 144.153 17.2207C144.248 17.3767 144.312 17.55 144.34 17.7306C144.368 17.9112 144.361 18.0957 144.318 18.2735C144.275 18.4513 144.198 18.6188 144.09 18.7667C143.962 18.9439 143.794 19.0882 143.599 19.1878C143.404 19.2875 143.189 19.3397 142.97 19.3402Z" fill={color} />
          <path d="M66.3523 6.67825C66.0557 6.67822 65.7669 6.58326 65.5281 6.40729C65.2892 6.23131 65.1129 5.98352 65.0247 5.70008C64.9704 5.52544 64.951 5.34181 64.9677 5.15968C64.9844 4.97756 65.0368 4.80051 65.1219 4.63865C65.207 4.4768 65.323 4.33331 65.4635 4.21639C65.604 4.09946 65.7662 4.01139 65.9407 3.95721C74.4068 1.32594 83.2219 -0.00820619 92.0867 3.79744e-05C99.0343 -0.00227229 105.958 0.815096 112.714 2.43523C113.071 2.52158 113.379 2.74564 113.572 3.05858C113.764 3.37151 113.825 3.74792 113.742 4.10571C113.655 4.46288 113.431 4.77143 113.119 4.96406C112.806 5.15669 112.43 5.21775 112.073 5.13397C105.529 3.56426 98.8232 2.77244 92.0942 2.77488C83.5104 2.76673 74.9747 4.05833 66.777 6.60586C66.64 6.65191 66.4967 6.67634 66.3523 6.67825Z" fill={color} />
          <path d="M32.6929 26.4066C32.5034 26.4069 32.3158 26.3683 32.1416 26.2933C31.9675 26.2183 31.8106 26.1084 31.6805 25.9704C31.4273 25.7021 31.2909 25.3441 31.3013 24.9751C31.3118 24.6062 31.4681 24.2565 31.7361 24.0029C33.1138 22.7037 34.5491 21.4304 36.001 20.2239C36.1417 20.1072 36.304 20.0194 36.4786 19.9655C36.6532 19.9116 36.8367 19.8928 37.0186 19.91C37.2005 19.9272 37.3772 19.9803 37.5386 20.066C37.7001 20.1517 37.843 20.2684 37.9592 20.4095C38.0756 20.5504 38.1632 20.7129 38.2169 20.8876C38.2705 21.0624 38.2893 21.246 38.2721 21.428C38.2549 21.61 38.202 21.7869 38.1165 21.9484C38.031 22.11 37.9145 22.2531 37.7738 22.3696C36.3645 23.5389 34.9737 24.7714 33.6405 26.0317C33.3834 26.2721 33.0448 26.4061 32.6929 26.4066Z" fill={color} />
          <path d="M6.24657 100.407C5.90825 100.407 5.58175 100.283 5.32814 100.059C5.07453 99.8348 4.91122 99.5258 4.86881 99.1898C4.40026 95.4782 4.16552 91.7407 4.16602 87.9995C4.16556 84.2764 4.39843 80.5569 4.86325 76.863C6.28168 65.5282 9.91404 54.5835 15.5534 44.6524C15.6433 44.4932 15.7636 44.3533 15.9075 44.2407C16.0514 44.1281 16.2161 44.045 16.3921 43.9962C16.5682 43.9474 16.7521 43.9339 16.9334 43.9563C17.1147 43.9788 17.2898 44.0368 17.4486 44.1271C17.6076 44.217 17.7474 44.3374 17.8599 44.4815C17.9724 44.6255 18.0553 44.7904 18.1041 44.9666C18.1528 45.1428 18.1664 45.3269 18.144 45.5083C18.1215 45.6898 18.0635 45.865 17.9733 46.024C12.514 55.6383 8.99696 66.2335 7.62251 77.2063C7.17265 80.7839 6.94719 84.3862 6.94751 87.9921C6.94699 91.616 7.17427 95.2363 7.62803 98.8316C7.65143 99.013 7.63886 99.1972 7.591 99.3737C7.54315 99.5502 7.46096 99.7156 7.34916 99.8602C7.23736 100.005 7.09815 100.126 6.9395 100.217C6.78084 100.307 6.60585 100.366 6.42458 100.389C6.36568 100.399 6.30623 100.405 6.24657 100.407Z" fill={color} />
          <path d="M48.8608 164.441C48.6174 164.441 48.3781 164.377 48.1673 164.255C44.7802 162.295 41.5268 160.113 38.4284 157.722C38.2837 157.61 38.1624 157.471 38.0714 157.313C37.9805 157.154 37.9216 156.979 37.8982 156.798C37.8748 156.616 37.8873 156.432 37.935 156.256C37.9827 156.079 38.0646 155.914 38.1762 155.769C38.2877 155.624 38.4266 155.503 38.585 155.412C38.7434 155.321 38.9182 155.262 39.0993 155.239C39.2805 155.215 39.4645 155.228 39.6408 155.275C39.8171 155.323 39.9823 155.405 40.1269 155.517C43.1276 157.832 46.2782 159.946 49.558 161.844C49.7161 161.935 49.8548 162.057 49.9659 162.202C50.077 162.347 50.1586 162.513 50.2058 162.689C50.2529 162.866 50.2649 163.05 50.241 163.231C50.217 163.412 50.1576 163.587 50.0661 163.745C49.9441 163.957 49.7685 164.133 49.5568 164.255C49.3452 164.377 49.1051 164.441 48.8608 164.441Z" fill={color} />
          <path d="M92.0822 176C84.2782 176.006 76.508 174.974 68.9754 172.932C68.7991 172.884 68.6339 172.802 68.4894 172.69C68.3448 172.579 68.2237 172.44 68.133 172.281C68.0422 172.122 67.9835 171.947 67.9604 171.766C67.9373 171.585 67.9501 171.401 67.9982 171.224C68.0459 171.048 68.1278 170.883 68.2394 170.738C68.351 170.593 68.49 170.472 68.6484 170.381C68.8069 170.29 68.9817 170.232 69.1628 170.208C69.344 170.185 69.5279 170.198 69.7041 170.246C76.9993 172.223 84.5244 173.222 92.0822 173.216C98.2048 173.218 104.31 172.563 110.294 171.263C110.472 171.224 110.656 171.22 110.836 171.253C111.016 171.285 111.188 171.352 111.341 171.451C111.495 171.55 111.628 171.678 111.732 171.828C111.836 171.979 111.909 172.148 111.948 172.327C112.026 172.687 111.958 173.064 111.759 173.375C111.559 173.685 111.245 173.904 110.885 173.983C104.707 175.325 98.4038 176.002 92.0822 176Z" fill={color} />
          <path d="M134.497 164.899C134.25 164.899 134.007 164.834 133.794 164.709C133.581 164.584 133.405 164.405 133.284 164.19C133.103 163.868 133.058 163.488 133.157 163.132C133.256 162.777 133.493 162.476 133.814 162.295C135.893 161.122 137.949 159.845 139.922 158.499C140.073 158.396 140.243 158.324 140.422 158.287C140.601 158.251 140.786 158.249 140.965 158.284C141.145 158.318 141.316 158.388 141.468 158.488C141.621 158.589 141.752 158.719 141.855 158.87C141.957 159.021 142.029 159.191 142.066 159.371C142.103 159.55 142.105 159.735 142.07 159.914C142.036 160.094 141.966 160.265 141.865 160.418C141.765 160.57 141.635 160.702 141.484 160.804C139.444 162.194 137.323 163.512 135.179 164.724C134.97 164.84 134.735 164.9 134.497 164.899Z" fill={color} />
          <path d="M156.28 147.461C155.938 147.46 155.607 147.332 155.353 147.103C155.08 146.855 154.916 146.508 154.897 146.139C154.879 145.77 155.007 145.409 155.255 145.135C164.752 134.621 171.481 121.904 174.835 108.132C174.922 107.773 175.147 107.463 175.462 107.271C175.777 107.079 176.156 107.019 176.515 107.106C176.692 107.149 176.86 107.227 177.007 107.334C177.155 107.442 177.279 107.578 177.375 107.734C177.47 107.89 177.533 108.064 177.561 108.245C177.589 108.425 177.581 108.61 177.538 108.787C174.075 123.01 167.125 136.144 157.317 147.002C157.186 147.148 157.026 147.264 156.848 147.343C156.669 147.422 156.476 147.462 156.28 147.461Z" fill={color} />
          <path d="M178.61 89.3915C178.427 89.3917 178.246 89.3559 178.077 89.286C177.908 89.2161 177.755 89.1136 177.626 88.9843C177.496 88.855 177.394 88.7014 177.324 88.5324C177.254 88.3634 177.219 88.1823 177.219 87.9994C177.219 85.4361 177.104 82.8487 176.876 80.3115C176.69 78.2308 176.425 76.1371 176.09 74.0861C176.03 73.7222 176.117 73.3494 176.332 73.0498C176.547 72.7502 176.872 72.5481 177.236 72.488C177.416 72.4581 177.6 72.464 177.778 72.5054C177.956 72.5469 178.124 72.623 178.273 72.7296C178.421 72.8362 178.547 72.971 178.643 73.1264C178.74 73.2818 178.804 73.4546 178.834 73.6351C179.181 75.7529 179.455 77.9153 179.646 80.0628C179.882 82.6836 180 85.3545 180 87.9994C180 88.3686 179.854 88.7227 179.593 88.9837C179.332 89.2448 178.978 89.3915 178.61 89.3915Z" fill={color} />
        </g>
        <path d="M120.066 32.2402L137.312 50.4299" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M45.5358 81.7982L43.5869 84.0868L45.5358 86.3772C51.7237 93.6438 63.2873 93.6438 69.4752 86.3772L71.4241 84.0868L69.4752 81.7982C63.2873 74.5223 51.7237 74.5223 45.5358 81.7982Z" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M57.5061 87.1724C59.2143 87.1724 60.5991 85.7863 60.5991 84.0764C60.5991 82.3666 59.2143 80.9805 57.5061 80.9805C55.7979 80.9805 54.4131 82.3666 54.4131 84.0764C54.4131 85.7863 55.7979 87.1724 57.5061 87.1724Z" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M57.5762 112.052V130.327C57.5762 131.486 58.036 132.597 58.8546 133.417C59.6733 134.236 60.7836 134.696 61.9413 134.696H132.947C134.105 134.696 135.215 134.236 136.034 133.417C136.852 132.597 137.312 131.486 137.312 130.327V51.1019C137.312 50.6761 137.143 50.2677 136.842 49.9666C136.542 49.6655 136.134 49.4964 135.708 49.4964H122.188C121.82 49.4964 121.467 49.3499 121.206 49.0892C120.946 48.8285 120.8 48.4749 120.8 48.1062V33.6602C120.8 33.2344 120.631 32.826 120.33 32.5249C120.029 32.2238 119.621 32.0547 119.195 32.0547H61.9413C60.7836 32.0547 59.6733 32.515 58.8546 33.3344C58.036 34.1538 57.5762 35.2651 57.5762 36.4239V55.9983" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M57.5756 112.052C73.0397 112.052 85.5759 99.5044 85.5759 84.0255C85.5759 68.5466 73.0397 55.9985 57.5756 55.9985C42.1114 55.9985 29.5752 68.5466 29.5752 84.0255C29.5752 99.5044 42.1114 112.052 57.5756 112.052Z" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M96.7021 69.1763H127.299" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M96.7021 79.7563H127.299" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M96.7021 90.3359H127.299" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M96.3057 100.183H114.65" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </>
  );
}