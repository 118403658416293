import { Col, Row } from "react-bootstrap";

import cancel from "./../../assets/icons/cancel.svg";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import EmailSignatureService from "../../services/EmailSignatureService";
import { DOCGUI } from "../../constants/GeneralConstants";

export const SSIModal = ({
  openSSIModal,
  setOpenSSIModal,
  signerGuid,
  docGuid,
}) => {
  const { t } = useTranslation();

  const handleContinueSSI = () => {
    localStorage.setItem(DOCGUI, docGuid);
    EmailSignatureService.getSSIUrl(signerGuid)
    .then((response) => {
        const { Url } = response.data.data;
        
        window.location.href = Url;
    });
  }

  return (
    <Modal
      title=""
      style={{ top: 150 }}
      footer={null}
      open={openSSIModal}
      closable={false}
    >
      <div className="modal-container">
        <p>{t("sign.auth_provider_title", { provider : 'SSI'})}</p>
        <p className="nu-input-label">{t("sign.auth_provider_description", { provider : 'SSI'})}</p>

        <Col className="action-buttons mobile-button">
          <Row>
            <Col md={12} xs={12} lg={12}>
              <button
                type="button"
                className="btn btn-primary btn-active"
                onClick={handleContinueSSI}
              >
                <span className="cancel-text">{t("general.continue")}</span>
                <img className="icon-image" src={cancel} alt="Check" />
              </button>
            </Col>
          </Row>
        </Col>
      </div>
    </Modal>
  );
};
