import { Modal } from "antd";
import cancel from "./../../../assets/icons/cancel.svg";
import { useRef, useState } from "react";
import "./AttachmentButton.css";
import { isDesktop } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { CheckIcon } from "../../../components/SvgComponents/CheckIcon/CheckIcon";
import { AttachmentIcon } from "../../../components/SvgComponents/AttachmentIcon/AttachmentIcon";
import { ArrowIcon } from "../../../components/SvgComponents/ArrowIcon/ArrowIcon";

export const AttachmentButton = ({
  attachmentData,
  setAttachmentData,
  buttonText,
}) => {
  const [openModal, setOpenModal] = useState(false);

  const inputFileRef = useRef(null);

  const { t } = useTranslation();

  const handleChange = () => {
    const file = inputFileRef.current.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      const base64Data = reader.result;

      setAttachmentData(base64Data);

      setOpenModal(true);
    };
  };

  const handleRemoveFile = () => {
    setAttachmentData(null);

    setOpenModal(false);

    inputFileRef.current.value = null;
  }

  const modalWidth = isDesktop ? "80%" : "100%";

  return (
    <>
      <button
        onClick={() => inputFileRef.current.click()}
        className={attachmentData ? "attached-button" : ""}
      >
        <span>{buttonText}</span>
        {attachmentData ? <CheckIcon /> : <AttachmentIcon />}
      </button>
      <input
        accept="image/*"
        onChange={handleChange}
        type="file"
        ref={inputFileRef}
        id="getFile"
        style={{ display: "none" }}
      />
      <Modal
        title=""
        width={modalWidth}
        footer={null}
        open={openModal}
        className="attached-image-modal"
        style={isDesktop ? {} : {
          top: 0,
          left: 0,
          position: 'absolute',
          minWidth: '100vw',
          minHeight: '100vh'
        }}
        closable={true}
        onCancel={handleRemoveFile}
      >
        <p className="attach-modal-instructions">
          {t('document_id.attachment_description')}
        </p>
        <div className="modal-container">
          <img className="attachment-img" src={attachmentData} alt="Image" />
        </div>
        <div className="modal-buttons-container">
          <button
            onClick={handleRemoveFile}
            className={attachmentData ? "attached-button" : ""}
          >
            <span>{t('general.cancel')}</span>
            <img src={cancel} alt="Attachment" />
          </button>
          <button
            onClick={() => setOpenModal(false)}
            className={attachmentData ? "attached-button" : ""}
          >
            <span>{t('general.continue')}</span>
            <ArrowIcon />
          </button>
        </div>
      </Modal>
    </>
  );
};
