import { useEffect, useState } from "react"
import SignatureService from "../../services/SignatureService";
import { FIRST_LOAD } from "../../constants/GeneralConstants";
import { QuestionsErrorModal } from "../Modals/QuestionsErrorModal/QuestionsErrorModal";

export const FormOnPreview = ({ setLoadingMorePages, areQuestionsAnswered, startCorrectSignMethod, signerGuid, formTransform, form, setFormOnPreview }) => {
    const [openQuestionErrorModal, setOpenQuestionErrorModal] = useState(false);
    
    useEffect(() => {
        const canContinue = areQuestionsAnswered();

        if (!canContinue) {
            setOpenQuestionErrorModal(true);
            return;
        }

        const array = [].concat(...formTransform);
        let result = [].concat(...array.map(val => val.components.map(comp => {
        return { id: comp.id, response: (comp.response === undefined ? comp.selectedchoice : comp.response) };
        })));
        result = result.filter(val => val.response !== undefined);
        setLoadingMorePages(true);
        SignatureService.signatureFormResponse(signerGuid, form, result, true)
        .then(() => {
            startCorrectSignMethod();
            localStorage.setItem(FIRST_LOAD, "false");
        })
        .catch(err => {
            setLoadingMorePages(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>
      <QuestionsErrorModal setOpenQuestionErrorModal={setOpenQuestionErrorModal} openQuestionErrorModal={openQuestionErrorModal} setFormOnPreview={setFormOnPreview} isAdvancedForm={true} />
    </>
}