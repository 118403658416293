import { useTranslation } from 'react-i18next';
import arrowRight from './../../assets/icons/arrow-right.svg';

import './PendingDocumentItem.css';

export const PendingDocumentItem = ({ item }) => {
    const { t } = useTranslation();
    const date = new Date(item.ExpirationDate);
    const day = date.getDate();
    const mouth = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDate = `${day}/${mouth}/${year}`;

    // Get frontend URL dynamically
    const frontendURL = process.env.REACT_APP_BACKEND_URL;
    const frontendURLBase = frontendURL.split('/api')[0];
    let originalURL = item.AccessURL;
    let updatedURL = originalURL.replace('https://pre-vidremote.vidsigner.net', frontendURLBase);

    const goToDocumentId = () => {
        const accessURL = updatedURL + '&isOpenedFromDocumentsList=true';
        window.top.location.href = accessURL;
    };

    return (
        <div className='document-container' onClick={() => goToDocumentId()}>
            {/* <Form>
                <Form.Check type="checkbox">
                    <Form.Check.Input type="checkbox" checked={isAdded} />
                    <Form.Check.Label></Form.Check.Label>
                </Form.Check>
            </Form> */}
            <div className='document-info-container'>
                <h5>{item.FileName}</h5>

                <p> {t('general.expiration_date')} {formattedDate}</p>
            </div>
            <img src={arrowRight} alt="Arrow right" />
        </div>
    );
}