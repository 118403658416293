import { useContext } from "react";
import "./Checkbox.css";
import { FormContext } from "../../contexts/FormContext";
import Helper from "../../util/helper";
import { CHECKBOX } from "../../constants/GeneralConstants";

const { Form } = require("react-bootstrap");

export const Checkbox = ({
  appearance,
  isAdvancedForm,
  componentData,
  onUpdateValue,
}) => {
  const titleValue = isAdvancedForm ? componentData.title.text : componentData.title;

  const { isHTML, checkDisabled, getScreenDimensions } = useContext(FormContext);
  const isDisabled = checkDisabled(componentData);
  const hasError = componentData.error;
  const hideBorders = isAdvancedForm && componentData.hideborders;

  const isCheckbox = appearance === "checkbox";
  const isRadiobutton = appearance === "radiobutton";

  const handleRadioButtonChange = (e) => {
    const value = e.target.id;

    onUpdateValue(value === "true");
  };

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;

    onUpdateValue(checked);
  };

  const getPositionStyles = () => {
    const { fontFamily } = getScreenDimensions();

    const { posx, posy, sizex } = componentData.title.position;
    return Helper.getElementPositionStylesNew(posx, posy, sizex, fontFamily);
  }

  return (
      <div style={isAdvancedForm ? getPositionStyles() : {}} className={` ${isAdvancedForm ? 'advanced-form' : ''}  ${isDisabled ? 'disabled-container' : ''}  ${hasError ? 'error-container' : ''} checkbox-container ${hideBorders? 'hide-checkbox-borders': ''}`}>
        {isRadiobutton && (
          <>
            {isHTML ? (
              <div
                dangerouslySetInnerHTML={Helper.mapInnerHtmlData(
                  titleValue
                )}
              />
            ) : (
              <label>{titleValue}</label>
            )}
            <Form className="inline-radio-buttons">
              <Form.Check
                type="radio"
                id="true"
                name="question-1"
                label="Sí"
                onChange={handleRadioButtonChange}
                checked={componentData.response}
              />
              <Form.Check
                type="radio"
                id="false"
                name="question-1"
                label="No"
                checked={componentData.response === false}
                onChange={handleRadioButtonChange}
              />
            </Form>
          </>
        )}
        {isCheckbox && (
          <Form>
            <Form.Check type="checkbox">
              <Form.Check.Input type="checkbox" checked={componentData.response} onChange={handleCheckboxChange} />
              {isHTML ? (
                <div
                  dangerouslySetInnerHTML={Helper.mapInnerHtmlData(
                    titleValue
                  )}
                />
              ) : (
                <Form.Check.Label>{titleValue}</Form.Check.Label>
              )}
            </Form.Check>
          </Form>
        )}
      </div>
  );
};
