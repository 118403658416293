import { Fragment, useEffect, useState } from "react";
import "./RGPDCheckbox.css";
import { useTranslation } from "react-i18next";
import { Col, Form } from 'react-bootstrap';
import RgpdTextService from "../../services/RgpdTextService";
import Helper from "../../util/helper";
import { RGPDPolicyModal } from "../Modals/RGPDPolicyModal/RGPDPolicyModal";


export const RGPDCheckbox = ({ rgpdRef, setInputRgpdValue, signerGuid, signerDTO, getWidthAndHeightContainers }) => {
  const { t } = useTranslation();
  const [showRgpd, setShowRgpd] = useState(false);
  const [rgpdInput, setRgpdInput] = useState(false);
  const [rgpdText, setRgpdText] = useState({});
  const [rgpdFields, setRgpdFields] = useState([]);
  const [openModalProtection, setOpenModalProtection] = useState(false);
  const [rgpdCheckboxText, setRgpdCheckboxText] = useState('');

  // On Init
  useEffect(() => {
    const lang = signerDTO.Language;
    const rgpdField = signerDTO.RGPDField;
    setShowRgpd(rgpdField);
    if (rgpdField) {
      RgpdTextService.rgpdAgreeText(signerGuid, lang)
        .then(response => {
          setRgpdCheckboxText(response.data.data.AgreeText);
        })
        .catch(err => {
          console.log(err)
        });
      RgpdTextService.rgpdText(signerGuid, lang)
        .then(response => {
          setRgpdText(response.data.data);
          const listItems = response.data.data.Fields?.map((field, index) => {
            return (
              <Fragment key={index}>
                <div className="title-field" dangerouslySetInnerHTML={Helper.mapInnerHtmlData(field.Key)} />
                <div className="value-field" dangerouslySetInnerHTML={Helper.mapInnerHtmlData(field.Value)} />
              </Fragment>
            )
          });

          setRgpdFields(listItems);
        })
        .catch(err => {
          console.log(err)
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (rgpdCheckboxText) {
      getWidthAndHeightContainers();
    }
  }, [rgpdCheckboxText])


  const handleCheckboxChange = (e) => {
    const value = !rgpdInput;
    setInputRgpdValue(value);
    setRgpdInput(value);
  };

  return (
    <>
      {showRgpd &&
        <div className="rgpd-text" ref={rgpdRef}>
          <Col md={12} lg={12} sm={12}>
            <Form.Check type="checkbox">
              <Form.Check.Input className="rgpd-input" type="checkbox" value={rgpdInput} onChange={handleCheckboxChange} />
              <Form.Check.Label className="rgpd-label">{rgpdCheckboxText} <span className="more-info" onClick={() => setOpenModalProtection(true)}>{t('sign.more_info')}</span></Form.Check.Label>
            </Form.Check>
          </Col>
        </div>
      }
      <RGPDPolicyModal rgpdText={rgpdText} rgpdFields={rgpdFields} openModalProtection={openModalProtection} setOpenModalProtection={setOpenModalProtection} />
    </>
  );
};
