import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import { CheckIcon } from "../../SvgComponents/CheckIcon/CheckIcon";

export const QuestionsErrorModal = ({ openQuestionErrorModal, setOpenQuestionErrorModal, setFormOnPreview, isAdvancedForm }) => {
  const { t } = useTranslation();

  const handleCloseModal = () => {
    setOpenQuestionErrorModal(false);
    if (isAdvancedForm) {
      setFormOnPreview(false);
    }
  }

  return (
    <Modal
      title=""
      style={{ top: 300 }}
      footer={null}
      open={openQuestionErrorModal}
      closable={true}
      onCancel={() => handleCloseModal()}
    >
      <div className="modal-container">
        <p className="nu-input-label">{t("question.error")}</p>

        <Col className="action-buttons mobile-button">
          <Row>
            <Col md={3} xs={3}></Col>
            <Col md={6} xs={6}>
              <button
                type="button"
                className="btn btn-primary btn-active"
                onClick={() => handleCloseModal()}
              >
                <span className="cancel-text">{t("general.ok")}</span>
                <CheckIcon />
              </button>
            </Col>
            <Col md={3} xs={3}></Col>
          </Row>
        </Col>
      </div>
    </Modal>
  );
};
