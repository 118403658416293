import React, { useEffect, useState } from 'react';

export const SmsDocumentIcon = () => {

  const [color, setColor] = useState('');
  const DOMstyle = document.documentElement.style;

  // On Init
  useEffect(() => {
    const color = DOMstyle.getPropertyValue("--custom-secondary-background-color");
    setColor(color);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <svg className="card-img-top img-step" width="160" height="100" viewBox="0 0 180 176" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M43.9154 70.2197H83.1901C84.7004 70.2197 86.1489 70.8203 87.2168 71.8892C88.2848 72.9582 88.8847 74.408 88.8847 75.9198V95.7688C88.8847 97.2806 88.2848 98.7304 87.2168 99.7994C86.1489 100.868 84.7004 101.469 83.1901 101.469H74.3301C74.239 101.469 74.1486 101.486 74.0644 101.521C73.9801 101.556 73.9036 101.607 73.8392 101.672C73.7747 101.736 73.7237 101.813 73.6889 101.897C73.6541 101.981 73.6363 102.072 73.6366 102.163V109.602C73.6362 109.739 73.5953 109.873 73.5191 109.987C73.4429 110.101 73.3347 110.19 73.2082 110.242C73.0817 110.294 72.9426 110.308 72.8083 110.282C72.674 110.255 72.5505 110.189 72.4535 110.092L64.0404 101.673C63.9764 101.608 63.9002 101.557 63.8161 101.522C63.7321 101.487 63.642 101.469 63.5509 101.469H43.9154C42.405 101.469 40.9566 100.868 39.8886 99.7994C38.8207 98.7304 38.2207 97.2806 38.2207 95.7688V75.9198C38.2207 75.1712 38.368 74.43 38.6541 73.7385C38.9403 73.0469 39.3598 72.4185 39.8886 71.8892C40.4174 71.3599 41.0452 70.9401 41.7361 70.6536C42.427 70.3672 43.1675 70.2197 43.9154 70.2197V70.2197Z" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M50.915 82.7393V89.4694" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M48.0049 84.4224L53.8275 87.7875" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M48.0049 87.7875L53.8275 84.4224" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M63.5537 82.7393V89.4694" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M60.6416 84.4224L66.4642 87.7875" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M60.6416 87.7875L66.4642 84.4224" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M76.1904 82.7393V89.4694" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M73.2793 84.4224L79.1019 87.7875" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M73.2793 87.7875L79.1019 84.4224" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M115.666 82.8452C115.666 82.8452 133.675 90.1582 136.36 92.7029C142.181 98.2192 143.741 112.006 143.741 119.616C143.741 123.757 145.092 128.351 146.029 131.033" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M128.023 101.669C124.032 97.1516 120.372 97.3001 117.396 94.3935C111.603 88.7343 112.15 89.1426 106.839 83.9994C103.687 80.9461 101.543 79.1531 98.3812 81.2635C95.724 83.0361 96.0077 85.3209 97.3503 88.2201C98.9895 91.7578 107.677 103.069 109.505 105.723C109.899 106.29 110.132 106.952 110.182 107.64C110.453 111.382 111.456 121.99 125.816 125.954" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M111.537 128.863C115.563 130.7 116.915 136.308 116.915 136.308" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M109.146 75.8101V47.4861C109.147 46.6079 108.976 45.738 108.641 44.9262C108.306 44.1145 107.814 43.3768 107.194 42.7553C106.574 42.1339 105.838 41.6408 105.027 41.3044C104.217 40.9681 103.348 40.7949 102.471 40.7949H66.8489C65.9707 40.7937 65.1008 40.9659 64.2892 41.3018C63.4776 41.6376 62.7403 42.1304 62.1193 42.752C61.4983 43.3736 61.0059 44.1117 60.6704 44.9241C60.3349 45.7364 60.1628 46.6071 60.1641 47.4861V62.823" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M60.1729 109.417V125.809C60.1716 126.689 60.3438 127.559 60.6793 128.372C61.0148 129.184 61.5071 129.922 62.1281 130.544C62.749 131.165 63.4865 131.658 64.2981 131.994C65.1097 132.33 65.9795 132.502 66.8577 132.501H102.47C104.241 132.501 105.939 131.797 107.191 130.544C108.442 129.291 109.146 127.591 109.146 125.819V120.696" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M109.145 52.7129H60.165" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M109.145 120.522H60.165" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <g opacity="0.3">
          <path d="M141.764 75.8584H138.797C138.428 75.8584 138.074 75.7117 137.814 75.4506C137.553 75.1896 137.406 74.8355 137.406 74.4663C137.406 74.0971 137.553 73.743 137.814 73.4819C138.074 73.2209 138.428 73.0742 138.797 73.0742H141.764C142.133 73.0742 142.487 73.2209 142.747 73.4819C143.008 73.743 143.155 74.0971 143.155 74.4663C143.155 74.8355 143.008 75.1896 142.747 75.4506C142.487 75.7117 142.133 75.8584 141.764 75.8584Z" fill={color} />
          <path d="M153.261 75.8584H149.923C149.554 75.8584 149.2 75.7117 148.94 75.4506C148.679 75.1896 148.532 74.8355 148.532 74.4663C148.532 74.0971 148.679 73.743 148.94 73.4819C149.2 73.2209 149.554 73.0742 149.923 73.0742H153.261C153.63 73.0742 153.983 73.2209 154.244 73.4819C154.505 73.743 154.652 74.0971 154.652 74.4663C154.652 74.8355 154.505 75.1896 154.244 75.4506C153.983 75.7117 153.63 75.8584 153.261 75.8584Z" fill={color} />
          <path d="M145.936 83.1899C145.567 83.1899 145.213 83.0433 144.952 82.7822C144.691 82.5211 144.545 82.1671 144.545 81.7979V78.8281C144.545 78.4589 144.691 78.1048 144.952 77.8438C145.213 77.5827 145.567 77.436 145.936 77.436C146.305 77.436 146.658 77.5827 146.919 77.8438C147.18 78.1048 147.326 78.4589 147.326 78.8281V81.7979C147.326 82.1671 147.18 82.5211 146.919 82.7822C146.658 83.0433 146.305 83.1899 145.936 83.1899Z" fill={color} />
          <path d="M145.936 72.0533C145.567 72.0533 145.213 71.9067 144.952 71.6456C144.691 71.3845 144.545 71.0305 144.545 70.6613V67.3203C144.545 66.9511 144.691 66.597 144.952 66.3359C145.213 66.0749 145.567 65.9282 145.936 65.9282C146.305 65.9282 146.658 66.0749 146.919 66.3359C147.18 66.597 147.326 66.9511 147.326 67.3203V70.6613C147.326 71.0305 147.18 71.3845 146.919 71.6456C146.658 71.9067 146.305 72.0533 145.936 72.0533Z" fill={color} />
          <path d="M4.35768 118.734H1.39075C1.0219 118.734 0.668172 118.588 0.407356 118.327C0.146539 118.066 0 117.711 0 117.342C0 116.973 0.146539 116.619 0.407356 116.358C0.668172 116.097 1.0219 115.95 1.39075 115.95H4.35768C4.72653 115.95 5.08026 116.097 5.34108 116.358C5.60189 116.619 5.74843 116.973 5.74843 117.342C5.74843 117.711 5.60189 118.066 5.34108 118.327C5.08026 118.588 4.72653 118.734 4.35768 118.734Z" fill={color} />
          <path d="M15.8545 118.734H12.5167C12.1479 118.734 11.7941 118.588 11.5333 118.327C11.2725 118.066 11.126 117.711 11.126 117.342C11.126 116.973 11.2725 116.619 11.5333 116.358C11.7941 116.097 12.1479 115.95 12.5167 115.95H15.8545C16.2234 115.95 16.5771 116.097 16.8379 116.358C17.0987 116.619 17.2453 116.973 17.2453 117.342C17.2453 117.711 17.0987 118.066 16.8379 118.327C16.5771 118.588 16.2234 118.734 15.8545 118.734Z" fill={color} />
          <path d="M8.5304 126.066C8.16155 126.066 7.80782 125.919 7.547 125.658C7.28619 125.397 7.13965 125.043 7.13965 124.674V121.704C7.13965 121.335 7.28619 120.981 7.547 120.72C7.80782 120.459 8.16155 120.312 8.5304 120.312C8.89925 120.312 9.25298 120.459 9.51379 120.72C9.77461 120.981 9.92115 121.335 9.92115 121.704V124.674C9.92115 125.043 9.77461 125.397 9.51379 125.658C9.25298 125.919 8.89925 126.066 8.5304 126.066Z" fill={color} />
          <path d="M8.5304 114.929C8.16155 114.929 7.80782 114.783 7.547 114.522C7.28619 114.261 7.13965 113.906 7.13965 113.537V110.196C7.13965 109.827 7.28619 109.473 7.547 109.212C7.80782 108.951 8.16155 108.804 8.5304 108.804C8.89925 108.804 9.25298 108.951 9.51379 109.212C9.77461 109.473 9.92115 109.827 9.92115 110.196V113.537C9.92115 113.906 9.77461 114.261 9.51379 114.522C9.25298 114.783 8.89925 114.929 8.5304 114.929Z" fill={color} />
          <path d="M165.128 24.8159H162.161C161.792 24.8159 161.439 24.6692 161.178 24.4082C160.917 24.1471 160.771 23.793 160.771 23.4238C160.771 23.0546 160.917 22.7005 161.178 22.4395C161.439 22.1784 161.792 22.0317 162.161 22.0317H165.128C165.497 22.0317 165.851 22.1784 166.112 22.4395C166.372 22.7005 166.519 23.0546 166.519 23.4238C166.519 23.793 166.372 24.1471 166.112 24.4082C165.851 24.6692 165.497 24.8159 165.128 24.8159Z" fill={color} />
          <path d="M176.625 24.8159H173.287C172.918 24.8159 172.565 24.6692 172.304 24.4082C172.043 24.1471 171.896 23.793 171.896 23.4238C171.896 23.0546 172.043 22.7005 172.304 22.4395C172.565 22.1784 172.918 22.0317 173.287 22.0317H176.625C176.994 22.0317 177.348 22.1784 177.608 22.4395C177.869 22.7005 178.016 23.0546 178.016 23.4238C178.016 23.793 177.869 24.1471 177.608 24.4082C177.348 24.6692 176.994 24.8159 176.625 24.8159Z" fill={color} />
          <path d="M169.3 32.1474C168.931 32.1474 168.577 32.0008 168.317 31.7397C168.056 31.4787 167.909 31.1246 167.909 30.7554V27.7856C167.909 27.4164 168.056 27.0623 168.317 26.8013C168.577 26.5402 168.931 26.3936 169.3 26.3936C169.669 26.3936 170.023 26.5402 170.283 26.8013C170.544 27.0623 170.691 27.4164 170.691 27.7856V30.7554C170.691 31.1246 170.544 31.4787 170.283 31.7397C170.023 32.0008 169.669 32.1474 169.3 32.1474Z" fill={color} />
          <path d="M169.3 21.0108C168.931 21.0108 168.577 20.8642 168.317 20.6031C168.056 20.3421 167.909 19.988 167.909 19.6188V16.2778C167.909 15.9086 168.056 15.5545 168.317 15.2935C168.577 15.0324 168.931 14.8857 169.3 14.8857C169.669 14.8857 170.023 15.0324 170.283 15.2935C170.544 15.5545 170.691 15.9086 170.691 16.2778V19.6188C170.691 19.988 170.544 20.3421 170.283 20.6031C170.023 20.8642 169.669 21.0108 169.3 21.0108Z" fill={color} />
          <path d="M24.9503 150.845C23.7554 150.843 22.6099 150.367 21.7651 149.521C20.9203 148.675 20.4453 147.529 20.4443 146.332C20.4453 145.136 20.9203 143.99 21.7651 143.144C22.6099 142.298 23.7554 141.822 24.9503 141.82C26.1456 141.821 27.2916 142.297 28.1368 143.143C28.982 143.989 29.4572 145.136 29.4582 146.332C29.4567 147.529 28.9814 148.676 28.1363 149.521C27.2912 150.367 26.1454 150.843 24.9503 150.845ZM24.9503 144.604C24.4928 144.605 24.0542 144.787 23.7308 145.111C23.4074 145.435 23.2258 145.874 23.2258 146.332C23.2263 146.79 23.4081 147.229 23.7313 147.553C24.0546 147.877 24.4929 148.06 24.9503 148.06C25.408 148.06 25.8469 147.878 26.1706 147.554C26.4942 147.23 26.6762 146.791 26.6767 146.332C26.677 146.105 26.6325 145.88 26.5459 145.671C26.4592 145.461 26.3321 145.27 26.1718 145.109C26.0114 144.949 25.8211 144.821 25.6115 144.734C25.4019 144.647 25.1772 144.603 24.9503 144.603V144.604Z" fill={color} />
          <path d="M173.287 63.5394C172.092 63.5379 170.946 63.062 170.101 62.2161C169.256 61.3703 168.781 60.2234 168.779 59.0272C168.78 57.8306 169.255 56.6833 170.101 55.8371C170.946 54.9908 172.092 54.5147 173.287 54.5132C174.482 54.5147 175.628 54.9905 176.473 55.8364C177.318 56.6823 177.794 57.8291 177.795 59.0254C177.794 60.2219 177.319 61.3692 176.474 62.2155C175.629 63.0618 174.483 63.5379 173.287 63.5394ZM173.287 57.2992C172.83 57.3002 172.391 57.4825 172.068 57.8064C171.744 58.1302 171.562 58.5692 171.561 59.0272C171.562 59.4852 171.744 59.9242 172.068 60.248C172.391 60.5719 172.83 60.7542 173.287 60.7552C173.745 60.7542 174.183 60.5719 174.507 60.248C174.83 59.9242 175.013 59.4852 175.014 59.0272C175.013 58.5689 174.831 58.1294 174.508 57.8052C174.184 57.4809 173.745 57.2983 173.287 57.2973V57.2992Z" fill={color} />
          <path d="M38.2921 52.4031C37.097 52.4017 35.9512 51.9258 35.1061 51.0799C34.261 50.234 33.7857 49.0872 33.7842 47.891C33.7852 46.6944 34.2604 45.5471 35.1055 44.7008C35.9506 43.8545 37.0966 43.3784 38.2921 43.377C39.4872 43.3784 40.633 43.8543 41.478 44.7002C42.3231 45.546 42.7985 46.6929 42.8 47.8891C42.799 49.0857 42.3238 50.233 41.4787 51.0793C40.6335 51.9255 39.4875 52.4017 38.2921 52.4031ZM38.2921 46.1629C37.8345 46.1639 37.396 46.3463 37.0725 46.6702C36.7489 46.994 36.5667 47.433 36.5657 47.891C36.5667 48.349 36.7489 48.7879 37.0725 49.1118C37.396 49.4356 37.8345 49.618 38.2921 49.619C38.7496 49.618 39.1881 49.4356 39.5117 49.1118C39.8352 48.7879 40.0175 48.349 40.0185 47.891C40.018 47.4326 39.836 46.9932 39.5124 46.669C39.1888 46.3447 38.75 46.1621 38.2921 46.1611V46.1629Z" fill={color} />
          <path d="M142.971 19.3402C142.677 19.3402 142.391 19.2466 142.154 19.0729C139.478 17.1241 136.692 15.3318 133.809 13.7051C133.488 13.5241 133.252 13.2228 133.152 12.8675C133.053 12.5121 133.098 12.1318 133.279 11.81C133.46 11.4883 133.761 11.2517 134.117 11.1522C134.472 11.0526 134.852 11.0983 135.174 11.2792C138.149 12.9592 141.024 14.8097 143.785 16.8215C143.933 16.9291 144.058 17.0647 144.154 17.2207C144.249 17.3767 144.313 17.55 144.341 17.7306C144.369 17.9113 144.362 18.0957 144.319 18.2735C144.276 18.4513 144.199 18.6189 144.091 18.7667C143.963 18.9439 143.795 19.0882 143.6 19.1878C143.405 19.2875 143.19 19.3397 142.971 19.3402Z" fill={color} />
          <path d="M66.3513 6.67825C66.0547 6.67822 65.766 6.58327 65.5271 6.40729C65.2883 6.23132 65.1119 5.98353 65.0237 5.70009C64.9694 5.52545 64.9501 5.34181 64.9667 5.15969C64.9834 4.97756 65.0359 4.80051 65.1209 4.63866C65.206 4.4768 65.3221 4.33332 65.4626 4.21639C65.6031 4.09947 65.7652 4.0114 65.9397 3.95722C74.4059 1.32594 83.221 -0.0082062 92.0858 3.79744e-05C99.0334 -0.00227229 105.957 0.815096 112.713 2.43523C113.07 2.52158 113.378 2.74565 113.571 3.05858C113.763 3.37151 113.824 3.74792 113.741 4.10571C113.654 4.46289 113.431 4.77144 113.118 4.96406C112.805 5.15669 112.429 5.21775 112.072 5.13397C105.528 3.56427 98.8223 2.77244 92.0932 2.77489C83.5095 2.76673 74.9738 4.05834 66.776 6.60586C66.639 6.65192 66.4958 6.67634 66.3513 6.67825Z" fill={color} />
          <path d="M32.694 26.4071C32.5044 26.4074 32.3168 26.3688 32.1427 26.2938C31.9685 26.2188 31.8116 26.1089 31.6815 25.9709C31.4283 25.7026 31.2919 25.3446 31.3023 24.9756C31.3127 24.6067 31.4691 24.257 31.7371 24.0034C33.1149 22.7042 34.5501 21.4309 36.0021 20.2244C36.1427 20.1077 36.305 20.0198 36.4796 19.966C36.6542 19.9121 36.8378 19.8932 37.0197 19.9105C37.2016 19.9277 37.3783 19.9807 37.5397 20.0665C37.7011 20.1522 37.844 20.2689 37.9603 20.41C38.0767 20.5509 38.1643 20.7134 38.2179 20.8881C38.2716 21.0629 38.2904 21.2465 38.2732 21.4285C38.256 21.6105 38.203 21.7873 38.1175 21.9489C38.032 22.1105 37.9156 22.2536 37.7748 22.3701C36.3655 23.5394 34.9748 24.7719 33.6415 26.0321C33.3844 26.2726 33.0458 26.4066 32.694 26.4071Z" fill={color} />
          <path d="M6.2475 100.408C5.90919 100.407 5.58264 100.283 5.32903 100.059C5.07542 99.8348 4.9121 99.5259 4.8697 99.1899C4.40114 95.4782 4.1665 91.7407 4.16699 87.9995C4.16653 84.2764 4.39936 80.5569 4.86418 76.863C6.28262 65.5283 9.91498 54.5836 15.5544 44.6524C15.6442 44.4932 15.7646 44.3533 15.9085 44.2407C16.0524 44.1281 16.2171 44.045 16.3931 43.9962C16.5691 43.9474 16.7531 43.9339 16.9344 43.9563C17.1156 43.9788 17.2907 44.0368 17.4496 44.1271C17.6086 44.217 17.7483 44.3374 17.8608 44.4815C17.9733 44.6255 18.0563 44.7904 18.1051 44.9666C18.1538 45.1428 18.1674 45.3269 18.1449 45.5083C18.1225 45.6898 18.0645 45.865 17.9743 46.024C12.515 55.6383 8.99786 66.2335 7.6234 77.2064C7.17354 80.784 6.94817 84.3863 6.94849 87.9921C6.94797 91.616 7.17526 95.2364 7.62902 98.8317C7.65241 99.0131 7.63984 99.1973 7.59199 99.3738C7.54413 99.5503 7.46194 99.7156 7.35014 99.8603C7.23835 100.005 7.09909 100.126 6.94043 100.217C6.78178 100.308 6.60679 100.366 6.42551 100.389C6.36662 100.399 6.30717 100.405 6.2475 100.408Z" fill={color} />
          <path d="M48.8618 164.44C48.6184 164.441 48.3791 164.377 48.1683 164.255C44.7812 162.295 41.5278 160.112 38.4294 157.721C38.2847 157.61 38.1634 157.471 38.0725 157.312C37.9815 157.154 37.9226 156.979 37.8992 156.797C37.8758 156.616 37.8882 156.432 37.9359 156.255C37.9836 156.079 38.0656 155.913 38.1771 155.769C38.2887 155.624 38.4276 155.503 38.586 155.411C38.7444 155.32 38.9192 155.261 39.1003 155.238C39.2815 155.215 39.4655 155.227 39.6418 155.275C39.8181 155.323 39.9833 155.405 40.1279 155.516C43.1286 157.832 46.2792 159.945 49.559 161.844C49.7172 161.935 49.8558 162.057 49.9669 162.202C50.0781 162.347 50.1596 162.512 50.2068 162.689C50.254 162.865 50.2659 163.049 50.242 163.23C50.218 163.412 50.1586 163.586 50.0671 163.744C49.9452 163.956 49.7695 164.132 49.5578 164.254C49.3462 164.377 49.1061 164.441 48.8618 164.44Z" fill={color} />
          <path d="M92.0822 176C84.2782 176.006 76.508 174.974 68.9754 172.932C68.7991 172.884 68.6339 172.802 68.4894 172.69C68.3448 172.579 68.2237 172.44 68.133 172.281C68.0422 172.122 67.9835 171.947 67.9604 171.766C67.9373 171.585 67.9501 171.401 67.9982 171.224C68.0459 171.048 68.1278 170.883 68.2394 170.738C68.351 170.593 68.49 170.472 68.6484 170.381C68.8069 170.29 68.9817 170.232 69.1628 170.208C69.344 170.185 69.5279 170.198 69.7041 170.246C76.9993 172.223 84.5244 173.222 92.0822 173.216C98.2049 173.218 104.31 172.563 110.294 171.263C110.472 171.224 110.656 171.22 110.836 171.253C111.016 171.285 111.188 171.352 111.341 171.451C111.495 171.55 111.628 171.678 111.732 171.828C111.836 171.979 111.909 172.148 111.948 172.327C112.026 172.687 111.958 173.064 111.759 173.375C111.56 173.685 111.245 173.904 110.885 173.983C104.707 175.325 98.4039 176.002 92.0822 176Z" fill={color} />
          <path d="M134.497 164.899C134.25 164.9 134.007 164.834 133.794 164.709C133.581 164.585 133.405 164.406 133.284 164.19C133.103 163.868 133.058 163.488 133.157 163.133C133.256 162.777 133.493 162.476 133.814 162.295C135.893 161.122 137.949 159.845 139.922 158.499C140.073 158.397 140.243 158.325 140.422 158.288C140.601 158.251 140.786 158.25 140.965 158.284C141.145 158.319 141.316 158.388 141.468 158.489C141.621 158.589 141.752 158.719 141.855 158.871C141.957 159.022 142.029 159.192 142.066 159.371C142.103 159.55 142.105 159.735 142.07 159.915C142.036 160.094 141.966 160.265 141.866 160.418C141.765 160.571 141.635 160.702 141.484 160.805C139.444 162.195 137.323 163.513 135.179 164.725C134.97 164.84 134.735 164.9 134.497 164.899Z" fill={color} />
          <path d="M156.279 147.461C155.937 147.46 155.606 147.332 155.352 147.103C155.079 146.855 154.915 146.508 154.896 146.139C154.878 145.77 155.006 145.409 155.254 145.135C164.751 134.621 171.481 121.904 174.834 108.132C174.921 107.773 175.147 107.463 175.462 107.271C175.777 107.079 176.155 107.019 176.514 107.106C176.691 107.149 176.859 107.227 177.006 107.334C177.154 107.442 177.279 107.578 177.374 107.734C177.469 107.89 177.532 108.064 177.56 108.245C177.588 108.425 177.58 108.61 177.537 108.787C174.074 123.01 167.124 136.144 157.316 147.002C157.185 147.148 157.025 147.264 156.847 147.343C156.668 147.422 156.475 147.462 156.279 147.461Z" fill={color} />
          <path d="M178.61 89.3915C178.427 89.3917 178.246 89.3559 178.077 89.286C177.908 89.2161 177.755 89.1136 177.626 88.9843C177.496 88.855 177.394 88.7014 177.324 88.5324C177.254 88.3634 177.219 88.1823 177.219 87.9994C177.219 85.4362 177.104 82.8488 176.876 80.3115C176.69 78.2308 176.425 76.1371 176.09 74.0861C176.03 73.7222 176.117 73.3494 176.332 73.0498C176.547 72.7502 176.872 72.5481 177.236 72.488C177.416 72.4581 177.6 72.464 177.778 72.5054C177.956 72.5469 178.124 72.623 178.273 72.7296C178.421 72.8362 178.547 72.971 178.643 73.1264C178.74 73.2818 178.804 73.4546 178.834 73.6351C179.181 75.7529 179.455 77.9153 179.646 80.0628C179.882 82.6836 180 85.3545 180 87.9994C180 88.3686 179.854 88.7227 179.593 88.9837C179.332 89.2448 178.978 89.3915 178.61 89.3915Z" fill={color} />
        </g>
      </svg>
    </>
  );
}