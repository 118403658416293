import React, { useContext, useEffect, useRef, useState } from 'react';
import "./DocumentPreview.css";
import { useLocation, useNavigate } from 'react-router-dom';
import DocumentService from '../../services/DocumentService';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import cancel from './../../assets/icons/cancel.svg';
import { ImageViewer } from '../../components/ImageViewer/ImageViewer';
import { SmallImageViewer } from '../../components/SmallImageViewer/SmallImageViewer';
import { DOCUMENT_PAGE_LIST, DOCUMENT_PAGE_VIEW, FIRST_LOAD, IS_FROM_BATCH_LIST, IS_SSI_AUTH_BATCH, LOADING_URL } from '../../constants/GeneralConstants';
import DownloadDocumentService from '../../services/DownloadDocumentService';
import FormS from 'react-bootstrap/Form';
import { Loader } from "../../components/Loader/Loader";
import { isDesktop, isMobile, isTablet } from 'react-device-detect';
import { publicIpv4 } from 'public-ip';
import { SimplifiedSignature } from '../../components/SignMethods/SimplifiedSignature/SimplifiedSignature';
import { SkipSMSAndDraw } from '../../components/SignMethods/SkipSMSandDraw/SkipSMSAndDraw';
import { OTPDraw } from '../../components/SignMethods/OTPDraw/OTPDraw';
import { FormOnPreview } from '../../components/FormPreviewTypes/FormOnPreview';
import { FormAfterPreview } from '../../components/FormPreviewTypes/FormAfterPreview';
import { Autofirma } from '../../components/Autofirma/Autofirma';
import { AutofirmaScripts } from '../../components/Autofirma/AutofirmaScripts';
import { RejectDocument } from '../../components/RejectDocument/RejectDocument';
import Helper from '../../util/helper';
import { Back } from '../../components/Back/Back';
import { SSIModal } from '../../components/SSIModal/SSIModal';
import { DownloadDocumentIcon } from '../../components/SvgComponents/DownloadDocumentIcon/DownloadDocumentIcon';
import { ArrowIcon } from '../../components/SvgComponents/ArrowIcon/ArrowIcon';
import { CriiptoModal } from '../../components/SignMethods/CriiptoModal/CriiptoModal';
import OneShotService from '../../services/OneShotService';
import { ShowErrorModal } from '../../components/Modals/ShowErrorModal/ShowErrorModal';
import { AOCValidModal } from '../../components/SignMethods/AOCValidModal/AOCValidModal';
import { SwisscomModal } from '../../components/SignMethods/SwisscomModal/SwisscomModal';
import { RenderScrollContext } from '../../contexts/RenderScrollContext';
import { HandIcon } from '../../components/SvgComponents/HandIcon/HandIcon';
import DisclaimerService from '../../services/DisclaimerService';
import { DocumentPreviewDisclaimerModal } from '../../components/Modals/DocumentPreviewDisclaimerModal/DocumentPreviewDisclaimerModal';

export const DocumentPreview = ({ form, areQuestionsAnswered, formTransform }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [documents, setDocuments] = useState({});
  const [documentRendered, setDocumentRendered] = useState([]);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingMorePages, setLoadingMorePages] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const refDocumentView = useRef(null);
  const refDocumentList = useRef(null);
  // Total pages loaded
  const [renderedPages, setRenderedPages] = useState(0);
  const [nextPageToRender, setNextPageToRender] = useState(1);
  const [allowDownloadDocument, setAllowDownloadDocument] = useState(false);
  const [zoomLevel, setZoomLevel] = React.useState(1);
  const [zoomStyles, handlers] = useImageZoom();
  const [scrollX, setScrollX] = useState({ overflowX: 'hidden' });
  const [emailId, setEmailId] = useState(null);
  const signerGuid = location.state.documents.SignerDTO.SignerGUI;
  const docGuid = location.state.documents.DocGUI;
  const email = location.state.documents.SignerDTO.eMail;
  const phoneNumber = location.state.documents.SignerDTO.PhoneNumber;
  const [numberOfPages, setNumberOfPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const isAutofirma = location.state.documents.SignerDTO.LocalSignature;

  const [clientIp, setClientIp] = useState('');
  const [ipData, setIpData] = useState('');
  const [simplifiedSignature, setSimplifiedSignature] = useState(false);
  const [skipSMSAndDraw, setSkipSMSAndDraw] = useState(false);
  const [otpDraw, setOTPDraw] = useState(false);
  const [formOnPreview, setFormOnPreview] = useState(false);
  const [formAfterPreview, setFormAfterPreview] = useState(false);
  const [autofirma, setAutofirma] = useState(false);
  const [openSSIModal, setOpenSSIModal] = useState(false);
  const [criiptoModal, setCriiptoModal] = useState(false);
  const [openShowErrorModal, setOpenShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loaderMessage, setLoaderMessage] = useState(t('general.loading'));
  const [aocValidModal, setAOCValidModal] = useState(false);
  const [swisscomModal, setOpenSwisscomModal] = useState(false);
  const [showModalDisclaimer, setShowModalDisclaimer] = useState(false);
  const [indexCount, setIndexCount] = useState(0);
  const [disclaimerTitle, setDisclaimerTitle] = useState('');
  const [disclaimerMessage, setDisclaimerMessage] = useState('');

  const ipLocation = require("iplocation");
  const { detect } = require("detect-browser");
  const browser = detect();

  const signatureDelegationInfo = location.state.documents.SignerDTO.SignatureDelegationInfo;
  const ifSignerData = location.state.documents.SignerDTO.SignerData;
  const isFromBatchList = localStorage.getItem(IS_FROM_BATCH_LIST) === "true";
  const hasForm = location.state.documents.SignerDTO.Form;
  const isFromQuestion = hasForm && location.state.documents.SignerDTO.Form.FormDisplay.toLowerCase() === "beforepreview";
  const isBeforePreviewForm = hasForm && location.state.documents.SignerDTO.Form.FormDisplay.toLowerCase() === "beforepreview";
  const isAfterPreviewForm = hasForm && location.state.documents.SignerDTO.Form.FormDisplay.toLowerCase() === "afterpreview";
  const isOnPreviewForm = hasForm && location.state.documents.SignerDTO.Form.FormDisplay.toLowerCase() === "onpreview";
  const disclaimerInfo = location.state.documents.SignerDTO.DisclaimerInfo;
  const otpCode = location.state.otpCode;
  const attachedImages = location.state.attachedImages;
  const isFirstLoad = localStorage.getItem(FIRST_LOAD);
  const isSsiBatch = localStorage.getItem(IS_SSI_AUTH_BATCH);

  const pageContext = useContext(RenderScrollContext);

  const handleMobileScroll = () => {
    const page = determinePageByDocumentViewVerticalScrollPosition(renderedPages);
    setCurrentPage(page);
    if (!loadingMorePages && (page + 1) >= (renderedPages - 1)) {
      renderDocument(page);
    }
  }

  // On Init
  useEffect(() => {
    setDocuments(location.state.documents);
    setEmailId(location.state.emailId);
    const documentContainer = document.getElementsByClassName('document-preview-container')[0];
    document.body.setAttribute('style', 'overflow-y: hidden;');

    documentContainer?.classList.add('document-preview-hidden');

    deleteDocumentRendered();
    setAllowDownloadDocument(location.state.documents.SignerDTO.AllowDownloadDocument);
    getClientData();
    checkDisclaimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pageContext.page.source === DOCUMENT_PAGE_LIST) {
      setScrollToPosition(
        determineScrollPositionByPageAndTotalSize('scroll-image', pageContext.page.value, renderedPages),
        'scroll-image');
    }
  }, [renderedPages, pageContext.page]);

  const getClientData = async () => {
    const clientIp = await publicIpv4();
    const ipData = await ipLocation(clientIp);
    setClientIp(clientIp);
    setIpData(ipData);
  };

  const checkBackNavigation = () => {
    return (ifSignerData || isFromBatchList || isBeforePreviewForm || (isFirstLoad === "false" && isAfterPreviewForm));
  }

  const shouldRequestMorePages = () => {
    const smallImageViewer = document.getElementById('scroll-small-image');

    const reachingToEnd = 0.90 * (smallImageViewer.scrollHeight - smallImageViewer.scrollTop) <= smallImageViewer.clientHeight;

    return reachingToEnd;
  }

  const handleSnapshotViewScroll = () => {
    if (!loadingMorePages && shouldRequestMorePages() && nextPageToRender !== -1) {
      requestMoreRenderPages(renderedPages);
    }
  };

  const getDocumentPagesRendered = async (docGuid, nextPageToRender) => {
    setLoading(true);
    await DocumentService.documentRendered(docGuid, nextPageToRender)
      .then(response => {
        const data = response.data.data;
        setDocumentRendered(data.DocPageRendered);
        localStorage.setItem("totalRenderedPages", data.DocPageRendered.length);
        setRenderedPages(data.DocPageRendered.length);
        setNextPageToRender(data.NextPage);
        setNumberOfPages(data.NumberOfPages);
        setLoading(false);

        const documentContainer = document.getElementsByClassName('document-preview-container')[0];
        document.body.setAttribute('style', 'overflow-y: auto;');

        documentContainer?.classList.remove('document-preview-hidden');
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  };

  const setScrollToPosition = (position, id) => {
    const container = document.getElementById(id);
    if (container) {
      container.style.scrollBehavior = 'smooth'; // Aplicamos el scroll suave
      // Add 20px to compensate offset and reach the desired page
      container.scrollTop = position + 20;
      setBlocked(true);

      // Después de un breve período, restablecemos scrollBehavior para que no afecte a otros scrolls
      setTimeout(() => {
        container.style.scrollBehavior = 'auto';
      }, 300);

      setTimeout(() => {
        setBlocked(false);
      }, 1000);
    }
  };

  const handleWheel = (event) => {
    const page = determinePageByDocumentViewScrollPosition(renderedPages);
    const correctCurrentPage = page + 1;
    setCurrentPage(correctCurrentPage);
    if (!blocked) {
      pageContext.setPage({
        value: page,
        source: DOCUMENT_PAGE_VIEW
      });
    }
    if (!loadingMorePages && correctCurrentPage === (renderedPages - 1)) {
      renderDocument(page);
    }
  }

  const determineScrollPositionByPageAndTotalSize = (scrollId, page, totalPages) => {
    const container = document.getElementById(scrollId);
    if (container) {
      const pageSize = container.scrollHeight / totalPages;
      return Math.floor(pageSize * page);
    }

    return 0;
  }

  const determinePageByDocumentViewScrollPosition = (totalPages) => {
    const scrollTop = refDocumentView.current.scrollTop;
    const pageSize = refDocumentView.current.scrollHeight / totalPages;

    return Math.floor(scrollTop / pageSize);
  }

  const determinePageByDocumentViewVerticalScrollPosition = (totalPages) => {
    const pagesContainer = document.getElementsByClassName('pdf-container')[0];
    const mobileView = document.getElementById('view-mobile');

    const scrollPosition = pagesContainer?.scrollTop;
    const pageHeight = mobileView?.clientHeight / totalPages;

    return Math.floor(scrollPosition / pageHeight) + 1;
  };

  const renderDocument = (page) => {
    var lastPagePreviousRendering = localStorage.getItem("totalRenderedPages") - 1;

    if (page === (lastPagePreviousRendering - 1) && nextPageToRender !== -1) requestMoreRenderPages(page);
  };

  const requestMoreRenderPages = (page) => {
    setLoadingMorePages(true);
      DocumentService.documentRendered(documents.DocGUI, nextPageToRender)
        .then(response => {
          const data = response.data.data;
          setDocumentRendered(documentRendered.concat(data.DocPageRendered));
          const totalRenderedPages = renderedPages + data.DocPageRendered.length;
          setRenderedPages(totalRenderedPages);
          setNextPageToRender(data.NextPage);
          localStorage.setItem("page", page);
          localStorage.setItem("totalRenderedPages", totalRenderedPages);
        })
        .finally(() => {
          setLoading(false);
          setLoadingMorePages(false);
        });
  }

  const createDownloadElementAndClick = (fileURL, fileName) => {
    var anchorElement = window.document.createElement('a');
    anchorElement.href = fileURL;
    anchorElement.target = '_self';
    anchorElement.download = fileName;
    var event = window.document.createEvent("MouseEvents");
    event.initEvent("click", true, false);
    anchorElement.dispatchEvent(event);
  }

  const handlerDownloadDocument = () => {
    DownloadDocumentService.downloadDocument(documents.DocGUI)
      .then(response => {
        const fileName = documents.FileName;
        const docContent = response.data.data.DocContent;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE workaround
          var byteCharacters = atob(docContent);
          var byteNumbers = new Array(byteCharacters.length);
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          var blob = new Blob([byteArray], { type: 'application/pdf' });
          window.navigator.msSaveOrOpenBlob(blob, fileName);
        }
        else {
          var newBlob = new Blob([Helper.base64ToArrayBuffer(docContent)], { type: 'application/octet-stream' });
          var fileURL = window.URL.createObjectURL(newBlob);
          createDownloadElementAndClick(fileURL, fileName);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  function useImageZoom(maxZoomLevel = 5) {
    const minZoomLevel = 1;
    function zoomIn() {
      setZoomLevel(zoomLevel =>
        zoomLevel < maxZoomLevel ? zoomLevel + 1 : zoomLevel
      );
    }

    function zoomOut() {
      setZoomLevel(zoomLevel =>
        zoomLevel > minZoomLevel ? zoomLevel - 1 : minZoomLevel
      );
    }

    function resetZoom() {
      setZoomLevel(minZoomLevel);
    }

    const zoomStyles = {
      transform: `scale(${zoomLevel})`,
      transformOrigin: zoomLevel > 1 ? 'top left' : 'center',
    };

    const handlers = {
      zoomIn,
      zoomOut,
      resetZoom
    }

    return [zoomStyles, handlers];
  }

  const onChangeHandler = (event) => {
    const level = parseFloat(event.target.value);
    setZoomLevel(level);
    setScrollX({ overflowX: 'hidden' });
    if (level > 1) {
      setScrollX({ overflowX: 'scroll' });
    }
  };

  const startCorrectSignMethod = () => {
    const { SkipSMS, SkipSigDraw, AuthWithSSI, AuthCriipto, AuthWithAOCValid, AuthWithSwisscom, OneShot } = documents.SignerDTO;

    const isSimplifiedSignature = SkipSMS && SkipSigDraw;

    //OneShot Flow
    if (OneShot) {
      oneShotFlow();
      return;
    }

    // SSI Flow
    if (AuthWithSSI && !isSsiBatch) return setOpenSSIModal(true);

    // Criipto Flow
    if (AuthCriipto) return setCriiptoModal(true);

    // AOCValid Flow
    if (AuthWithAOCValid) return setAOCValidModal(true);

    // Swisscom Flow
    if (AuthWithSwisscom) return setOpenSwisscomModal(true);

    // Simplified signature flow
    if (isSimplifiedSignature) return setSimplifiedSignature(true);

    // SMS, Draw flow
    if (SkipSMS && !SkipSigDraw) return setSkipSMSAndDraw(true);

    // OTP + Draw flow
    return setOTPDraw(true);
  }

  const handlerSign = async () => {
    const firstLoad = localStorage.getItem(FIRST_LOAD);

    // AdvancedPreview flow
    if (documents.SignerDTO.Form && (!formDisplayIsNullOrEmpty() && documents.SignerDTO.Form.FormDisplay.toLowerCase() === "afterpreview" && firstLoad === "true")) return setFormAfterPreview(true);

    // OnPreview flow
    if (documents.SignerDTO.Form && (!formDisplayIsNullOrEmpty() && documents.SignerDTO.Form.FormDisplay.toLowerCase() === "onpreview")) return setFormOnPreview(true);
    setLoadingMorePages(true);

    // Autofirma
    if (typeof isAutofirma !== 'undefined' && isAutofirma !== 'no') return setAutofirma(true);

    //Continue with correct sign method
    startCorrectSignMethod();
  };

  const formDisplayIsNullOrEmpty = () => {
    return (documents.SignerDTO.Form.FormDisplay === null || documents.SignerDTO.Form.FormDisplay === '');
  };

  const handleBack = () => {
    let link = "/loading" + localStorage.getItem(LOADING_URL);
    if (documents.SignerDTO.SignerData) {
      link = "/signer-data";
    }

    if (isFromQuestion) {
      link = "/questions"
    }

    navigate(link, {
      state: {
        documents: documents,
        emailId: emailId,
        isFromDocument: true
      }
    });
  };

  const handleSignatureDelegation = () => {
    navigate("/signature-delegation", {
      state: {
        documents: documents,
        emailId: emailId,
        signatureDelegationInfo: signatureDelegationInfo
      }
    });
  };

  const oneShotFlow = () => {
    setLoaderMessage(t('document_detail.one_shot.generating_signature_request'));
    OneShotService.createRequest(documents.SignerDTO.SignerGUI)
      .then(res => {
        setLoaderMessage(t('document_detail.sending'));
        const requestId = res.data.data.RequestId;
        OneShotService.sendOTP(documents.SignerDTO.SignerGUI, requestId)
          .then(response => {
            setLoadingMorePages(false);
            if (!response) {
              setOpenShowErrorModal(true);
              const errorMessage = t('document_detail.one_shot.send_otp_error_message');
              setErrorMessage(errorMessage);
              return;
            }

            navigate("/sms", {
              state: {
                documents,
                phoneNumber: phoneNumber,
                emailId,
                requestId
              }
            });

          })
          .catch(error => {
            setLoadingMorePages(false);
            setOpenShowErrorModal(true);
            const errorMessage = t('document_detail.one_shot.send_otp_error_message');
            setErrorMessage(errorMessage);
          });
      })
      .catch(err => {
        setLoadingMorePages(false);
        setOpenShowErrorModal(true);
        const errorMessage = t('document_detail.one_shot.create_request_error_message');
        setErrorMessage(errorMessage);
      });
  };

  const deleteDocumentRendered = async () => {
    if (isFirstLoad === "true" && (isAfterPreviewForm || isOnPreviewForm)) {
      await DocumentService.deleteDocumentRendered(docGuid)
        .then(response => {
          getDocumentPagesRendered(docGuid, nextPageToRender);
        })
        .catch(error => {
          console.error(error);
        });
    } else {
      await getDocumentPagesRendered(docGuid, nextPageToRender);
    }
  }

  const checkDisclaimer = () => {
    setShowModalDisclaimer(false);
    if (disclaimerInfo.Display) {
      getDisclaimerMessage();
      return;
    }
  }

  const getDisclaimerMessage = () => {
    const disclaimerTypes = disclaimerInfo.DisclaimerTypes;
    if ((indexCount + 1) <= disclaimerTypes.length) {
      const type = disclaimerTypes[indexCount];
      DisclaimerService.getDisclaimer(signerGuid, type)
        .then(
          response => {
            setDisclaimerTitle(response.data.data.Title);
            setDisclaimerMessage(response.data.data.Text);
            setShowModalDisclaimer(true);
            setIndexCount(prevCount => prevCount + 1);
          }
        );
        return;
    }
  }

  return (
    <>
      {loading && <Loader />}
      {simplifiedSignature && <SimplifiedSignature documents={documents} signerGuid={signerGuid} setLoadingMorePages={setLoadingMorePages} emailId={emailId} docGuid={docGuid} email={email} />}
      {skipSMSAndDraw && <SkipSMSAndDraw documents={documents} emailId={emailId} />}
      {otpDraw && <OTPDraw documents={documents} emailId={emailId} setLoadingMorePages={setLoadingMorePages} otpCode={otpCode} attachedImages={attachedImages} setOTPDraw={setOTPDraw} />}
      {autofirma && <Autofirma startCorrectSignMethod={startCorrectSignMethod} signerDTO={documents.SignerDTO} clientIp={clientIp} ipData={ipData} browser={browser} setOTPDraw={setOTPDraw} emailId={emailId} email={email} docGuid={docGuid} setAutofirma={setAutofirma} />}
      <AutofirmaScripts />
      <Container className="document-preview-container">
        <Row>
          <Col md={12} lg={12} className="document-preview-title">
            {checkBackNavigation() &&
              <Back handleBack={handleBack} />
            }
            {!checkBackNavigation() && <HandIcon />}
            <span className="signer-name"><strong> {documents?.SignerDTO?.SignerName}, </strong>{t('document_detail.warning')}</span>
          </Col>
        </Row>
        {(isMobile || isTablet) && <hr></hr>}
        <Row className="align-items-center">
          <Col md={allowDownloadDocument ? 9 : 10} lg={allowDownloadDocument ? 9 : 10} xs={8} className="document-name d-flex">
            <span>{documents?.FileName}</span>
          </Col>
          {(isDesktop) &&
            <Col md={allowDownloadDocument ? 2 : 2} lg={allowDownloadDocument ? 2 : 2} className={`scale-zoom d-flex ${allowDownloadDocument ? 'justify-content-center' : 'justify-content-end'}`}>
              {!form && <FormS.Select id="scale-zoom" onChange={onChangeHandler} defaultValue={1}>
                <option value="0.75">50%</option>
                <option value="0.90">75%</option>
                <option value="1">100%</option>
                <option value="1.25">125%</option>
                <option value="1.5">150%</option>
              </FormS.Select>}
            </Col>
          }
          {allowDownloadDocument &&
            <Col md={1} lg={1} xs={3} className="download-button d-flex justify-content-center">
              <span onClick={handlerDownloadDocument} style={{ cursor: 'pointer' }}>
                <DownloadDocumentIcon />
              </span>
            </Col>
          }
        </Row>
        <Row>
          {(isDesktop) &&
            <>
              <Col md={3} sm={3} lg={3}>
                <div id="scroll-small-image" onScrollEnd={(e) => console.log('end')} ref={refDocumentList} className="pdf-container-small" onScroll={handleSnapshotViewScroll}>
                  <SmallImageViewer
                    images={documentRendered}
                  >
                  </SmallImageViewer>
                </div>
              </Col>
              <Col md={1} sm={1} lg={1}></Col>
              <Col md={8} sm={8} lg={8} xs={12} id="desktop-container">
                <div id="scroll-image" ref={refDocumentView} onScroll={handleWheel} className="pdf-container" style={scrollX}>
                  <ImageViewer
                    form={form}
                    images={documentRendered}
                    zoomStyles={zoomStyles}
                  >
                  </ImageViewer>
                </div>
                <Col className="number-page">
                  <strong><span className="current-number"> {currentPage}</span> <span style={{marginRight: "5px"}}>/</span><span>{numberOfPages} </span></strong>
                </Col>
              </Col>
            </>
          }
          {
            (isMobile || isTablet) &&
            <>
              <Col sm={12}>
                <div className="pdf-container" onScroll={handleMobileScroll} >
                  <div id="view-mobile" className="view-mobile-doc">
                    <ImageViewer
                      form={form}
                      images={documentRendered}
                      zoomStyles={zoomStyles}
                    >
                    </ImageViewer>
                  </div>
                </div>
              </Col>
              <Col className="number-page">
                <strong><span className="current-number"> {currentPage}</span> <span style={{marginRight: "5px"}}>/</span><span>{numberOfPages} </span></strong>
              </Col>
            </>
          }
        </Row>
        <Row className="action-buttons mobile-button document-preview-buttons">
          {!signatureDelegationInfo.Allow &&
            <Col md={1} lg={1}></Col>
          }
          <Col md={signatureDelegationInfo.Allow ? 4 : 5} lg={signatureDelegationInfo.Allow ? 4 : 5} xs={signatureDelegationInfo.Allow ? 4 : 6}>
            <button type="button" className="btn btn-secondary btn-cancel btn-mobile" onClick={() => setOpenRejectModal(true)}>
              <span className="document-preview-text"> {t('general.reject')} </span>
              <img className="document-icon-image" src={cancel} alt="Cancel" />
            </button>
          </Col>
          <Col md={signatureDelegationInfo.Allow ? 4 : 5} lg={signatureDelegationInfo.Allow ? 4 : 5} xs={signatureDelegationInfo.Allow ? 4 : 6}>
            <button disabled={loadingMorePages} type="button" className="btn btn-primary btn-active btn-mobile" onClick={() => handlerSign()}>
              <span className="document-preview-text"> {t('general.sign')} </span>
              <ArrowIcon />
            </button>
          </Col>
          {signatureDelegationInfo.Allow &&
            <Col md={signatureDelegationInfo.Allow ? 4 : 5} lg={signatureDelegationInfo.Allow ? 4 : 5} xs={signatureDelegationInfo.Allow ? 4 : 6}>
              <button disabled={loadingMorePages} type="button" className="btn btn-primary btn-active btn-mobile btn-delegate" onClick={() => handleSignatureDelegation()}>
                <span className="document-preview-text"> {t('signature_delegation.delegate')} </span>
                <ArrowIcon secondayButton={true} />
              </button>
            </Col>
          }
          {!signatureDelegationInfo.Allow &&
            <Col md={1} lg={1}></Col>
          }
        </Row>
      </Container>
      {
        loadingMorePages &&
        <div className='loading-pages-container'>
          <Loader loaderMessage={loaderMessage} />
        </div>
      }
      {formOnPreview && <FormOnPreview setLoadingMorePages={setLoadingMorePages} areQuestionsAnswered={areQuestionsAnswered} startCorrectSignMethod={startCorrectSignMethod} signerGuid={signerGuid} formTransform={formTransform} form={form} setFormOnPreview={setFormOnPreview} />}
      {formAfterPreview && <FormAfterPreview emailId={emailId} documents={documents} />}
      <RejectDocument openRejectModal={openRejectModal} setOpenRejectModal={setOpenRejectModal} signerGuid={signerGuid} emailId={emailId} docGuid={docGuid} email={email} />
      <SSIModal signerGuid={signerGuid} openSSIModal={openSSIModal} setOpenSSIModal={setOpenSSIModal} docGuid={docGuid} />
      <CriiptoModal criiptoProvider={documents?.SignerDTO?.AuthCriipto} signerGuid={signerGuid} openCriiptoModal={criiptoModal} setOpenCriiptoModal={setCriiptoModal} setParentLoading={setLoadingMorePages} />
      <ShowErrorModal openShowErrorModal={openShowErrorModal} setOpenShowErrorModal={setOpenShowErrorModal} errorMessage={errorMessage} />
      <AOCValidModal signerGuid={signerGuid} openAOCValidModal={aocValidModal} setOpenAOCValidModal={setAOCValidModal} setParentLoading={setLoadingMorePages} />
      <SwisscomModal signerGuid={signerGuid} openSwisscomModal={swisscomModal} setOpenSwisscomModal={setOpenSwisscomModal} phoneNumber={documents?.SignerDTO?.PhoneNumber} email={email} emailId={emailId} docGuid={docGuid} setParentLoading={setLoadingMorePages} />
      <DocumentPreviewDisclaimerModal showModalDisclaimer={showModalDisclaimer} setShowModalDisclaimer={setShowModalDisclaimer} title={disclaimerTitle} message={disclaimerMessage} />
    </>
  );
};
