import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import './RejectedSuccess.css';
import { useLocation, useNavigate } from 'react-router-dom';
import cancel from "./../../assets/icons/cancel.svg";
import { CheckIcon } from "../../components/SvgComponents/CheckIcon/CheckIcon";
import { SignatureCompletionImage } from "../../components/SvgComponents/SignatureCompletionImage/SignatureCompletionImage";
import { useContext, useEffect, useState } from "react";
import { URLReturnContext } from "../../contexts/URLReturnContext";
import { RedirectingModal } from "../../components/Modals/RedirectingModal/RedirectingModal";

export const RejectedSuccess = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [isRedirecting, setRedirecting] = useState(false);
  const { urlReturn } = useContext(URLReturnContext);

  const pendingDocuments = location.state.pendingDocuments;
  const emailId = location.state.emailId;
  const email = location.state.email;

  const hasPendingDocuments = pendingDocuments.length > 0;

  const goToPendingDocuments = () => {
    navigate("/pending-documents", {
      state: {
        pendingDocuments,
        emailId,
        email
      }
    });
  };

  const handleRedirect = () => {
    setRedirecting(true);

    window.location.href = urlReturn;
  }

  useEffect(() => {
    if (urlReturn) {
      handleRedirect();
    }
  }, []);

  return (
    <>
      <Container className="container-row row-box">
        <Row>
          <Col md={2}></Col>
          <Col md={8} xs={12} className="justify-content-center container-box">
            <Row>
              <SignatureCompletionImage />
              <h2 className="rejected-title">{t("document_detail.modal2.header")}</h2>
              <p className="rejected-subtitle">{t("popup_process.subtitle")}</p>
              {hasPendingDocuments ? (
                <>
                  <hr className="success-hr" />
                  <p>
                    {t("popup_process.pending_documents_1")}{" "}
                    <span className="green-text">
                      {t("popup_process.pending_documents_2", { pending: pendingDocuments.length })}
                    </span>{" "}
                    {t("popup_process.pending_documents_3")}
                  </p>
                  <button type="button" className="btn btn-primary btn-active documents-list-button" onClick={goToPendingDocuments}
                  >
                    {t("popup_process.next_document_button")}
                    <CheckIcon />
                  </button>
                </>
              ) : (
                <button type="button" className="btn btn-secondary btn-cancel exit-button">
                  <Row>
                    <Col className="text-align-start">Salir</Col>
                    <Col className="text-align-end">
                      <img className="icon-image" src={cancel} alt="Check" />
                    </Col>
                  </Row>
                </button>
              )}
            </Row>
          </Col>
          <Col md={2}></Col>
        </Row>
      </Container>
      <RedirectingModal isRedirecting={isRedirecting} />
    </>
  );
};
