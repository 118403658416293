import { useEffect, useState } from "react";
import i18n from "../../config/i18n";
import OauthApiService from "../../services/OauthService";
import { Loader } from "../../components/Loader/Loader";
import { getEvidenceData } from "../../util/getEvidenceData";
import Helper from "../../util/helper";
import gzip from "gzip-js";
import { useRef } from "react";
import { isMobile } from "react-device-detect";
import EmailSignatureService from "../../services/EmailSignatureService";
import { Col, Container, Row } from "react-bootstrap";
import SignaturePad from "react-signature-canvas";
import cancel from "./../../assets/icons/cancel.svg";
import { useTranslation } from "react-i18next";
import { useOrientation } from "./../../hooks/useOrientation";
import { ArrowIcon } from "../../components/SvgComponents/ArrowIcon/ArrowIcon";
import { SignatureCompletionImage } from "../../components/SvgComponents/SignatureCompletionImage/SignatureCompletionImage";
import ThemeService from "../../services/ThemeService";

import './SignaturePanel.css';

export const SignaturePanelView = () => {
  const { isLandscape, isPortrait } = useOrientation();

  const [loading, setLoading] = useState(true);
  const [tokenId, setTokenId] = useState(null);
  const [disable, setDisable] = useState(true);
  const [sendingData, setSendingData] = useState(false);
  const [success, setSuccess] = useState(false);

  const { t } = useTranslation();

  const sigPad = useRef({});

  const [canvasWidth, setCanvasWidth] = useState(null);
  const [canvasHeight, setCanvasHeight] = useState(null);

  const containerRef = useRef();
  const buttonsRef = useRef();

  const getToken = async (emailId) => {
    OauthApiService.getToken(emailId)
      .then(() => {
        ThemeService.setTheme(emailId);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const decodeToken = (token) => {
    const tokenParts = token.split(",");
    const jsonTokenInfo = atob(tokenParts[0]);
    const tokenInfo = JSON.parse(jsonTokenInfo);

    return tokenInfo;
  };

  useEffect(() => {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const qrToken = url.searchParams.get("token");

    const tokenInfo = decodeToken(qrToken);
    const { EmailId, TokenId, Language } = tokenInfo;
    i18n.changeLanguage(Language);
    setTokenId(TokenId);

    getToken(EmailId);
  }, []);

  const sendGraphData = async () => {
    let points;
    let image;

    points = Helper.computePressureForSignaturePoints(sigPad.current.toData());
    image = sigPad.current.toDataURL();

    const evidenceData = await getEvidenceData();

    let signatureRawDataZippedAndEncoded = "";
    const timestampStart = points[0][0].time;

    const signatureRawDataStr = points.reduce((str, element) => {
      const stroke = element.reduce((str, rd, i) => {
        const time = rd.time - timestampStart;
        const { x, y, pressure } = rd;
        const letter = i === 0 ? "U" : "T";
        return `${str}${time}:${x}:${y}:${pressure}:${letter};`;
      }, "");
      return `${str}${stroke}`;
    }, "");

    signatureRawDataZippedAndEncoded = Helper.base64ArrayBuffer(
      gzip.zip(signatureRawDataStr)
    );

    let signatureImageRequest = image.substr(22);

    const body = {
      SignatureImage: signatureImageRequest,
      SignatureRawData: signatureRawDataZippedAndEncoded,
      ...evidenceData,
    };

    setSendingData(true);

    EmailSignatureService.sendQRGraphData(tokenId, body)
      .then(() => {
        setSuccess(true);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setSendingData(false);
      })
  };

  const handleDraw = () => {
    setDisable(false);

    const canvas = sigPad.current.getCanvas();
    if (canvas) {
      canvas.classList.remove('canvas-with-image');
    }
  };

  const clear = () => {
    sigPad.current.clear();
    setDisable(true);

    const canvas = sigPad.current.getCanvas();
    if (canvas) {
      canvas.classList.add('canvas-with-image');
    }
  };

  const getWidthAndHeightContainers = () => {
    let width = loading ? window.screen.width : containerRef?.current?.clientWidth;
    width = width - 40;
    const height = isPortrait ? 418 : 200;

    setCanvasWidth(width);
    setCanvasHeight(height);
  };

  useEffect(() => {
    getWidthAndHeightContainers();
  }, [isLandscape, isPortrait, success, loading]);

  return loading ? (
    <Loader />
  ) : (
        success ? <Container className="container-row row-box">
        <Row>
          <Col md={2}></Col>
          <Col md={8} xs={12} className="justify-content-center container-box">
            <Row>
              <SignatureCompletionImage />
              <h2 className="rejected-title">{t("sign.sign_correct")}</h2>
              <p className="rejected-subtitle">{t("sign.finish_process_computer")}</p>
            </Row>
          </Col>
          <Col md={2}></Col>
        </Row>
      </Container> :
    <Row className="signature-panel-container" ref={containerRef}>
      <Col md={12} lg={12}>
        <Row className="document-preview-title title-sign">
          <Col md={5} sm={5} className="document-preview-title title-sign">
            <span className="signer-name"><strong> {t('sign.draw_signature')} </strong> </span>
          </Col>
          {
            isLandscape && 
              <Col md={7} lg={7} xs={7} className='landscape-buttons-container'>
                <button
                  type="button"
                  className="btn btn-secondary btn-cancel button-canvas"
                  onClick={() => clear()}
                >
                  <Row>
                    <Col>{t("sign.erase")}</Col>
                    <Col className="text-align-end">
                      <img className="icon-image" src={cancel} alt="Cancel" />
                    </Col>
                  </Row>
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-active button-canvas"
                  disabled={disable || sendingData}
                  onClick={() => sendGraphData()}
                >
                  <Row>
                    <Col>{t("general.finish")}</Col>
                    <Col className="text-align-end">
                      <ArrowIcon />
                    </Col>
                  </Row>
                </button>
            </Col>
          }
        </Row>
        <SignaturePad
          id="sp"
          penColor='#000'
          canvasProps={{ width: canvasWidth, height: canvasHeight, className: 'signatureCanvas' }}
          ref={sigPad}
          onBegin={() => handleDraw()}
          onEnd={() => handleDraw()}
        />
        {isPortrait && <Row className="action-buttons mobile-button" ref={buttonsRef}>
          <Col md={6} lg={6} xs={6}>
            <button
              type="button"
              className="btn btn-secondary btn-cancel button-canvas"
              onClick={() => clear()}
            >
              <span className="cancel-text">{t("sign.erase")}</span>
              <img className="icon-image" src={cancel} alt="Cancel" />
            </button>
          </Col>
          <Col md={6} lg={6} xs={6}>
            <button
              type="button"
              className="btn btn-primary btn-active button-canvas"
              disabled={disable || sendingData}
              onClick={() => sendGraphData()}
            >
              <span className="cancel-text">{t("general.finish")}</span>
              <ArrowIcon />
            </button>
          </Col>
          <Col md={1} lg={1}></Col>
        </Row>}
      </Col>
    </Row>
  );
};
