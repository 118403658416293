import { createContext, useState } from "react";

export const BatchContext = createContext(null);

export const BatchContextProvider = ({ children }) => {
    const [batchDocuments, setBatchDocuments] = useState([]);
    const [selectedBatchDocumentGuids, setSelectedBatchDocuments] = useState([]);
    const [batchOTP, setBatchOTP] = useState('');
    const [batchAttachedImages, setBatchAttachedImages] = useState(null);

    const remainingBatchDocuments = batchDocuments.length;

    const addDocumentsToBatch = (newDocuments) => {
        setBatchDocuments(newDocuments);
    }

    const selectBatchDocument = (docGuid) => {
        const newGuidsList = [
            ...selectedBatchDocumentGuids,
            docGuid,
        ];
        setSelectedBatchDocuments(newGuidsList);
    }
    
    const unselectBatchDocument = (docGuid) => {
        const newGuidsList = selectedBatchDocumentGuids.filter(docInListGuid => docInListGuid !== docGuid);

        setSelectedBatchDocuments(newGuidsList);
    }

    const selectAllBatchDocuments = () => {
        const allGuids = batchDocuments.map((document => document.DocGUI));
        setSelectedBatchDocuments(allGuids);
    }
    
    const unselectAllBatchDocument = () => {
        setSelectedBatchDocuments([]);
    }

    const isDocumentSelected = (docGuid) => {
        return !!selectedBatchDocumentGuids.find(docInListGuid => docInListGuid === docGuid);
    } 

    const removeDocumentsFromBatch = (docGuids) => {
        const newBatchList = batchDocuments.filter((document) => !docGuids.find(docGuid => document.DocGUI === docGuid));

        setBatchDocuments(newBatchList);

        // After deleting documents from batch, we reset the selected ones
        unselectAllBatchDocument();
    }

    const selectedBatchDocuments = batchDocuments.filter((batchDoc) => selectedBatchDocumentGuids.find((docGuid) => docGuid === batchDoc.DocGUI));

    return (
        <BatchContext.Provider value={{
            batchDocuments,
            remainingBatchDocuments,
            removeDocumentsFromBatch,
            addDocumentsToBatch,
            batchOTP,
            setBatchOTP,
            selectBatchDocument,
            unselectBatchDocument,
            isDocumentSelected,
            selectedBatchDocumentGuids,
            selectAllBatchDocuments,
            unselectAllBatchDocument,
            selectedBatchDocuments,
            batchAttachedImages,
            setBatchAttachedImages,
        }}>
            {children}
        </BatchContext.Provider>
    );
};