export const SHOW_ONBOARDING_PAGE = "SHOW_ONBOARDING_PAGE";
export const DOCUMENT_PAGE_VIEW = "DOCUMENT_PAGE_VIEW";
export const DOCUMENT_PAGE_LIST = "DOCUMENT_PAGE_LIST";
export const CHECKBOX = "checkbox";
export const RADIOBUTTON = "radiobutton";
export const RADIOBUTTON_LABEL = "radiobutton-label";
export const TEXTBOX = "textbox";
export const TITLE = "title";
export const ATTACHMENT = "attachments";
export const FIRST_LOAD = "FIRST_LOAD";
export const IS_CUSTOM = "IS_CUSTOM";
export const LOADING_URL = "LOADING_URL";
export const DOCGUI = "DOCGUI";

export const A4_WIDTH_MM = 210;
export const A4_HEIGHT_MM = 297;
export const MMTOPXFACTOR = 3.7795275591;
export const SCALE_DESKTOP = 0.68;
export const SCALE_SIZE = 0.55;
export const SCALE_MOBILE = 0.8;
export const PXTOMMFACTOR = 0.26458333333;
export const IMAGEWIDTH = 1239;
export const IMAGEHEIGHT = 1754;
export const SCALE = 2;

export const FILE = "File";
export const CAMERA = "Camera";

export const IS_OPENED_FROM_DOCUMENTS_LIST = "IS_OPENED_FROM_DOCUMENTS_LIST";
export const IS_FROM_BATCH_LIST = "IS_FROM_BATCH_LIST";
export const IS_SSI_AUTH_BATCH = "IS_SSI_AUTH_BATCH";

export const EMAIL_CLICKED = "EMAIL_CLICKED";