import React, { createContext, useState, useEffect } from 'react';

// Crear un contexto para manejar el idioma globalmente
export const LangContext = createContext();

export const LangProvider = ({ children }) => {
  const [lang, setLang] = useState(''); // Idioma por defecto

  // Cada vez que cambia el idioma, actualiza el atributo 'dir' en el documento
  useEffect(() => {
    const dir = lang === 'ar' ? 'rtl' : 'ltr';
    document.documentElement.setAttribute('dir', dir);
  }, [lang]);

  return (
    <LangContext.Provider value={{ lang, setLang }}>
      {children}
    </LangContext.Provider>
  );
};