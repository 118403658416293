import { Button } from "react-bootstrap";
import "./Attachment.css";
import { useContext, useEffect, useRef, useState } from "react";
import cancelIcon from './../../assets/icons/cancel.svg';
import Helper from "../../util/helper";
import { FormContext } from "../../contexts/FormContext";
import { CAMERA, FILE } from "../../constants/GeneralConstants";
import { AttachmentIcon } from "../SvgComponents/AttachmentIcon/AttachmentIcon";
import { useTranslation } from "react-i18next";

export const Attachment = ({ isAdvancedForm, componentData, onUpdateValue }) => {
  const titleValue = isAdvancedForm ? componentData.title.text : componentData.title;

  const inputFileRef = useRef();
  const [uploadedFile, setFile] = useState(null);
  const { isHTML, checkDisabled, formTransform, getScreenDimensions } = useContext(FormContext);
  const isDisabled = checkDisabled(componentData);
  const hasError = componentData.error;
  const { t } = useTranslation();
  const [contentType, setContentType] = useState('image/*');

  useEffect(() => {
    if (componentData.response.fileinbase64 === null) {
      setFile(null);
    }

    if (componentData.response.contenttype === 'PDF') {
      setContentType('application/pdf');
    }
  }, [formTransform]);

  const handleChange = () => {
    const file = inputFileRef.current.files[0];
    let reader = new FileReader();
    const fileDate = file.lastModified;
    const currentTime = new Date().getTime();
    const difference = currentTime - fileDate;
    const oneMinuteInMillis = 60000; // 1 minute in milliseconds
    let source = FILE;

    if (difference < oneMinuteInMillis) {
      source = CAMERA;
    }

    reader.readAsDataURL(file);
    reader.onloadend = function () {
      const base64Data = reader.result;

      setFile({
        name: file.name,
        size: file.size,
        base64Data,
      });

      onUpdateValue({
        fileinbase64: base64Data,
        position: componentData.response.position,
        source: source
      });
    };
  };

  const handleRemoveFile = () => {
    setFile(null);

    onUpdateValue({
      fileinbase64: null,
      position: componentData.response.position,
      source: null
    });

    inputFileRef.current.value = null;
  }

  const handleUploadFile = () => {
    inputFileRef.current.click();
  }

  const formatFileSize = (sizeInBytes) => {
    if (sizeInBytes > 1000000) return `${sizeInBytes / 1000000} MB`;
    if (sizeInBytes > 1000) return `${sizeInBytes / 1000} KB`;
  }

  const getTitleStyles = () => {
    const { fontFamily } = getScreenDimensions();

    const { posx, posy, sizex } = componentData.title.position;
    return Helper.getElementPositionStylesNew(posx, posy, sizex, fontFamily);
  }

  const getResponseStyles = () => {
    const { fontFamily } = getScreenDimensions();
  
    const position = componentData?.response?.position || {};
  
    const { posx = 0, posy = 0, sizex } = position;
  
    return Helper.getElementPositionStylesNew(posx, posy, sizex, fontFamily);
  }

  const contentTypeIsImage = () => {
    return contentType.includes('image');
  }

  return (
    <div className={`${isDisabled ? 'disabled-container' : ''}  ${hasError ? 'error-container' : ''} ${isAdvancedForm ? 'advanced-form' : ''}  attachment-container`}>
      {isHTML ? (
        <div style={isAdvancedForm ? getTitleStyles() : {}}
          dangerouslySetInnerHTML={Helper.mapInnerHtmlData(titleValue)}
        />
      ) : (
        <label style={isAdvancedForm ? getTitleStyles() : {}}>{titleValue}</label>
      )}
      <Button
        disabled={isDisabled}
        style={isAdvancedForm ? getResponseStyles() : {}}
        variant="success"
        className="file-button"
        onClick={handleUploadFile}
      >
        {t('document_id.upload_file')}
      </Button>
      <input
        accept={contentType}
        onChange={handleChange}
        type="file"
        ref={inputFileRef}
        id="getFile"
        style={{ display: "none" }}
      />
      {uploadedFile && (
        <>
          {
            contentTypeIsImage() &&
            <img className="attachment-img" style={isAdvancedForm ? getResponseStyles() : {}} src={uploadedFile.base64Data} />
          }
          <div style={isAdvancedForm ? getResponseStyles() : {}} className="file-info-container">
            <AttachmentIcon />
            <label>{uploadedFile.name}</label>
            <label>{formatFileSize(uploadedFile.size)}</label>
            <img src={cancelIcon} onClick={handleRemoveFile} alt="cancel" />
          </div>
        </>

      )}
    </div>
  );
};
